import React, { useState, useEffect } from 'react';
import config from '../config';
import { Link } from 'react-router-dom';
import './surviourGrid.css';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import parse from 'html-react-parser';

const SurviourGrid = () => {
    const [gridData, setGridData] = useState([]);
    const [articleList, setArticleList] = useState([]);
    const [showMoreArticles, setShowMoreArticles] = useState(false);
    const [expandedIndex, setExpandedIndex] = useState(null);

    useEffect(() => {
        fetch(`${config.baseUrl}/list-grid`)
            .then(response => response.json())
            .then(data => setGridData(data.data))
            .catch(error => console.error('Error fetching grid data:', error));
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/list-article`);
                const data = await response.json();
                setArticleList(data.data);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, []);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const toggleReadMore = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const getShortText = (text, isExpanded) => {
        const wordLimit = 20;
        const words = text.split(' ');
        if (words.length <= wordLimit || isExpanded) {
            return parse(text); // Use parse to render HTML safely
        }
        return parse(words.slice(0, wordLimit).join(' ') + '...'); // Parse the truncated text as well
    };

    // Function to get the label based on future value
    const getFutureLabel = (value) => {
        if (value >= 1 && value <= 2) {
            return 'Low';
        }
        else if (value == 0.5) {
            return 'Low';
        } else if (value > 2 && value <= 4) {
            return 'Medium';
        } else if (value > 4 && value <= 5) {
            return 'High';
        }
        return ''; // Return empty string if it doesn't fit any criteria
    };
    const toggleShowMoreArticles = () => {
        setShowMoreArticles((prevState) => !prevState);
    };

    const getImageUrl = (imagePath) => {
        return `${config.imageUrl}/${imagePath}`;
    };

    return (
        <>
            <Header />
            <div className='surviour-top'>
                <div className='container'>
                    <div className='surviour-heading'>
                        <h2>All Things Survivor</h2>
                        <p>Explore expert strategies with Wade's Survivor Grid, insightful articles, and podcast episodes designed to help you outlast the competition and win big.</p>
                    </div>
                </div>
            </div>
            <div className="table-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">WC</th>
                                        <th scope="col">WWP</th>
                                        <th scope="col">WPP</th>
                                        <th scope="col">Team</th>
                                        {gridData.length > 0 && gridData[0].week_info.slice().map((week, index) => (
                                            <th key={index} scope="col">{week.week_originalname}</th>
                                        ))}

                                        <th scope="col" style={{ width: '80px' }}>Future Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {gridData.map(grid => (
                                        <tr key={grid.id}>
                                            <td>{grid.expected_value}</td>
                                            <td>{grid.win_percentage}%</td>
                                            <td>{grid.major_percentage}%</td>
                                            <td>{grid.team}</td>
                                            {grid.week_info.slice().map((week, index) => {
                                                const getBackgroundColor = (spread) => {
                                                    if (spread === undefined || spread === null) return ''; // Handle undefined or null spread
                                                    if (typeof spread !== 'number') return '#fefffe'; // Check if it's a number
                                                    if (spread >= 0) return '#fefffe'; // For values 0 and above
                                                    if (spread < -3 && spread >= -7) return '#e3ffe3';  // for values -3 to -7
                                                    if (spread < -7 && spread >= -10) return '#b3ffb3'; // for values -7 to -10
                                                    if (spread < -10 && spread >= -13) return '#85ff85'; // for values -10 to -13
                                                    if (spread < -13 && spread >= -16) return '#57ff57'; // for values -13 to -16
                                                    return ''; // Default (no background color)
                                                };

                                                const backgroundColor = getBackgroundColor(week.spread); // Get background color based on spread

                                                return (
                                                    <td
                                                        key={index}
                                                        className={week.status === 1 ? 'h-green' : ''}
                                                        style={{ backgroundColor }} // Set background color
                                                    >
                                                        <sub>{week.spread}</sub> {week.opponent_originalname}
                                                    </td>
                                                );
                                            })}
                                            <td className='future-grid'>
                                                {getFutureLabel(grid.future)} {/* Call the function to get the label */}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spread">
                <p>
                    <strong>Spread Key:</strong>
                </p>
                <ul>
                    <li>0</li>
                    <li>3</li>
                    <li>7</li>
                    <li>10</li>
                    <li>16+</li>
                </ul>
                <p />
            </div>
            <div className='grid-content'>
                <div className='container'>
                    <ul>
                        {/* <li>Wc (1* ) | WP (2** ) | WPP (3 *****) </li> */}
                        <li>Wade’s Confidence (WC): This number is between 1 and 5 and represents the value Wade thinks you're getting picking a certain team considering all factors. 5 is high confidence and 1 is low confidence that the team is a good pick.</li>
                        <li>Wade’s Win Percentage (WWP): A combination of implied win percentage and Wade's formula to generate the chances a team wins the game.</li>
                        <li>Wade's Pick Percentage (WPP): A combination of data from various large survivor pools and Wade's formula to generate our best projection of how many people will pick various teams each week.</li>
                    </ul>
                </div>
            </div>
            <div className='surviour-stategies'>
                <div className='container'>
                    <h2>Survivor Strategies</h2>
                    {articleList.map((item, index) => (
                        <div key={index} className='article-box gridarticle-box'>
                            <div className='row'>
                                <div className='col-md-4 d-flex'>
                                    <img src={getImageUrl(item.image)} alt="" class="img-fluid full-height" />
                                </div>
                                <div className='col-md-8'>
                                    <div className='article-content'>
                                        <h5>{formatDate(item.created_at)}</h5>
                                        <h4>{item.heading}</h4>
                                        {getShortText(item.paragraph, expandedIndex === index)}
                                        <button
                                            onClick={() => toggleReadMore(index)}
                                            className="read-more-less-btn"
                                        >
                                            {expandedIndex === index ? "Read Less" : "Read More"}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className='Surviour-btn load-morebtn survoiur-loadmore'>
                        <Link to="#" onClick={toggleShowMoreArticles}>
                            {showMoreArticles ? 'Load Less' : 'Load More'}
                        </Link>
                    </div>
                </div>
            </div>

            <section className='survvivor-strategy-blk insidescop surviour-expert'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='circa-heading wade-bakery'>
                                <h2>Hear From The Expert</h2>
                            </div>
                            <div className='membership-heading wade-catch '>
                                <p className="catch-latest">Catch Our Latest Podcast for Winning Tips</p>
                            </div>
                            <div className='grid-postcard-blk'>
                                <div className='common-blk'>
                                    <div className='imgblk'>
                                        <img alt="" src="images/poscast.png" />
                                    </div>
                                    <div className='Surviour-btn upgrade-btn'>
                                        <Link to="/wade-deli">LISTEN TO THE PODCAST</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default SurviourGrid;
