import React, { useState, useEffect } from 'react';
import config from '../../config'; 
import './SurvivorPool.css'
import Header from '../../Components/Common/Header';
import Footer from '../../Components/Common/Footer';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Leaderboard from './Leaderboard';

const nflWeeks = [
    { week: '1', startDate: '2024-09-05', endDate: '2024-09-11' },
    { week: '2', startDate: '2024-09-12', endDate: '2024-09-18' },
    { week: '3', startDate: '2024-09-19', endDate: '2024-09-25' },
    { week: '4', startDate: '2024-09-26', endDate: '2024-10-02' },
    { week: '5', startDate: '2024-10-03', endDate: '2024-10-09' },
    { week: '6', startDate: '2024-10-10', endDate: '2024-10-16' },
    { week: '7', startDate: '2024-10-17', endDate: '2024-10-23' },
    { week: '8', startDate: '2024-10-24', endDate: '2024-10-29' },
    { week: '9', startDate: '2024-10-31', endDate: '2024-11-06' },
    { week: '10', startDate: '2024-11-07', endDate: '2024-11-13' },
    { week: '11', startDate: '2024-11-14', endDate: '2024-11-20' },
    { week: '12', startDate: '2024-11-21', endDate: '2024-11-23' },
    { week: '13', startDate: '2024-11-28', endDate: '2024-12-04' },
    { week: '14', startDate: '2024-12-05', endDate: '2024-12-11' },
    { week: '15', startDate: '2024-12-12', endDate: '2024-12-18' },
    { week: '16', startDate: '2024-12-19', endDate: '2024-12-25' },
    { week: '17', startDate: '2024-12-26', endDate: '2025-01-01' },
    { week: '18', startDate: '2025-01-02', endDate: '2025-01-05' }
];

const SurvivorPool = () => {
    const [teamSelections, setTeamSelections] = useState([]); 
    const [filteredMatches, setFilteredMatches] = useState([]); 
    const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek()); 
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    const [successMessage, setSuccessMessage] = useState(''); 
    const [selectedTeams, setSelectedTeams] = useState({});  
    const [selectionMessage, setSelectionMessage] = useState('');
    const [showLeaderboard, setShowLeaderboard] = useState(false);
    const [messageColor, setMessageColor] = useState('');
    const [selectedTeamForMatch, setSelectedTeamForMatch] = useState(null);
    const [matches, setMatches] = useState([]);

    const navigate = useNavigate();

    // Mock week data for filtering
   
    function getCurrentWeek() {
        const today = new Date();  // Get today's date and time
        today.setHours(0, 0, 0, 0);  // Set time to start of the day
    
        for (const week of nflWeeks) {
            const startDate = new Date(week.startDate);
            const endDate = new Date(week.endDate);
    
            startDate.setHours(0, 0, 0, 0);  
            endDate.setHours(23, 59, 59, 999);  
    
            if (today >= startDate && today <= endDate) {
                return week.week;  // Return the current week
            }
        }
        return null;  // No current week found
    }
    
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        } else {
            fetchTeams();
            fetchUserData(token);
            fetchUserSelections(token);
            setCurrentWeek();
            fechMatches(token);
        }
    }, [navigate]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && userData) {
            fetchUserSelections(token); 
            setSelectionMessage("")
        }
    }, [userData]); 

     // Fetch teams from api
     const fetchTeams = () => {
        fetch(`${config.baseUrl}/get-teams`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}` 
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (Array.isArray(data)) {
                    setTeamSelections(data);
                    filterMatchesByWeek(data, selectedWeek); // Filter matches when data is loaded
                } else {
                    console.error('Unexpected data format:', data);
                }
            })
            .catch(error => {
                setErrorMessage('Error fetching team selections. Please try again.');
                console.error('Error fetching team selections:', error);
            });
    };
    //console.log(teamSelections, "fetch teamsss...")

    const fechMatches = async(token) =>{
        try {
            const response = await fetch(`${config.baseUrl}/get-matches`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            });
            const data = await response.json();
            
            if (data) {
               // console.log("User Data:", data.data);
                setMatches(data);
            } else {
                throw new Error('Invalid user data format');
            }
        } catch (error) {
            setErrorMessage('Failed to fetch user data: ' + error.message);
            console.error('Error fetching user data:', error);
        }
    }
//    console.log(matches, "matches...ABCJHD")
    const setCurrentWeek = () => {
        const currentWeek = getCurrentWeek();  // Get the current week based on today's date
        
        // Function to find the next available week with match data
        const findNextAvailableWeek = (startingWeek) => {
            for (let i = startingWeek - 1; i < nflWeeks.length; i++) {
                const week = nflWeeks[i].week;
                filterMatchesByWeek(teamSelections, week);
                if (filteredMatches.length > 0) {
                    return week; // Return the first week with available matches
                }
            }
            return null; // Return null if no further weeks have data
        };
        
        if (currentWeek) {
            filterMatchesByWeek(teamSelections, currentWeek); // Filter matches for the current week
           // console.log(filteredMatches, "filter match")
            if (filteredMatches.length === 0) {  // If no data for the current week, move to the next week
                const nextWeek = findNextAvailableWeek(parseInt(currentWeek));
                if (nextWeek) {
                    setSelectedWeek(nextWeek); // Set the next available week as the selected week
                    filterMatchesByWeek(teamSelections, nextWeek); // Filter matches for the next week
                } else {
                    console.log("No upcoming week with data found.");
                }
            } else {
                setSelectedWeek(currentWeek); // If data exists, set the current week
            }
        } else {
            const nextWeek = findNextAvailableWeek(1); // If no current week found, start from week 1
            if (nextWeek) {
                setSelectedWeek(nextWeek);
                filterMatchesByWeek(teamSelections, nextWeek);
            } else {
                console.log("No available week data found.");
            }
        }
    };
    
     

    const fetchUserData = async (token) => {
        try {
            const response = await fetch(`${config.baseUrl}/welcome`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            });
            const data = await response.json();
            
            if (data.data) {
               // console.log("User Data:", data.data);
                setUserData(data.data);
            } else {
                throw new Error('Invalid user data format');
            }
        } catch (error) {
            setErrorMessage('Failed to fetch user data: ' + error.message);
            console.error('Error fetching user data:', error);
        }
    };

const fetchUserSelections = async (token) => {
        if (!userData) return; // Ensure user data is available
        try {
            const url = `${config.baseUrl}/user-selections/${userData.id}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (response.status === 404) {
                setSelectionMessage("Select a team to join the Pool");
                return; 
            }
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
          //  console.log('User selections response:', data); // Log the fetched data
    
            if (data && Array.isArray(data.data)) { // Adjusted to check for data.data
                if (data.data.length === 0) {
                    // Set message if there are no selections
                    setSelectionMessage("Select a team to join the Pool");
                    return; // Early return if no selections
                }
    
                const teamSelectionsMap = data.data.reduce((acc, selection) => {
                    acc[selection.match_id] = selection.selected_team; 
                    return acc;
                }, {});

                const currentDate = new Date(); 
                let isUserOut = false;

                const messages = data.data.map(selection => {
                    let message = ''; // Initialize message variable
                    switch (selection.result) {
                        case 0:
                            message = `You have selected ${selection.selected_team}: Not playing this week.`;
                            break;
                        case 1:
                            isUserOut = true;
                            message = `Sorry, your team ${selection.selected_team} lost. You are out of the pool.`;
                            break;
                        case 2:
                            message = `Your team ${selection.selected_team} tied! You can continue to the next week.`;
                            break;
                        case 3:
                            message = `Congratulations! Your team ${selection.selected_team} won! You can continue to the next week.`;
                            break;
                        default:
                            message = `You have already selected: ${selection.selected_team}. Match not Playing Yet!`;
                            break;
                    }
                    return message; // Return the generated message
                });
                // Join messages and set selection message
                setSelectionMessage(messages.join(' '));
                setSelectedTeams(teamSelectionsMap);
             
                if (isUserOut) {
                    setErrorMessage('You are out of the pool. No further selections allowed.');
                }

            } else {
                throw new Error('Invalid selections data format');
            }
        } catch (error) {
            setErrorMessage('Failed to fetch user selections: ' + error.message);
            console.error('Error fetching user selections:', error);
        }
    };
    

    // Filter matches by week
    const filterMatchesByWeek = (matches, week) => {
        const selectedWeekData = nflWeeks.find(w => w.week === week);
        if (!selectedWeekData) return;

        const { startDate, endDate } = selectedWeekData;
        const filtered = matches.filter(match => {
            const matchDate = new Date(match.match_date);
            return matchDate >= new Date(startDate) && matchDate <= new Date(endDate);
        });
        setFilteredMatches(filtered);
    };

    // Handle week selection change
    const handleWeekChange = (event) => {
        const week = event.target.value;
        setSelectedWeek(week);
        filterMatchesByWeek(teamSelections, week); // Filter the matches when the week is changed
    };

    // Handle poll selection
const handlePoll = (selectedTeam, matchId, matchDate) => {
        setSelectedTeamForMatch(selectedTeam);
        if(!userData){
            setErrorMessage('User data is not available');
            return;
        }
        if (errorMessage.includes('out of the pool')) {
            setErrorMessage('You cannot make a new selection as you are out of the pool.');
            return; 
        }

        const userId = userData.id;
        const username = userData.first_name || 'Anonymous';
        const email = userData.email;
        const phone = userData.phoneno || 'N/A';

        console.log(selectedTeam, "selected team")

        if (!matchId || !matchDate || !selectedTeam) {
            setErrorMessage('Match ID, selected team, and match date are required');
            return;
        }
        const formattedDate = new Date(matchDate).toISOString().slice(0, 19).replace('T', ' ');
        const payload = {
            selectedTeam,
            match_date: formattedDate,
            userId,
            username,
            email,
            phone,
            result: 0
        };

        fetch(`${config.baseUrl}/submit-selection/${matchId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(payload),
        })
        .then(response => response.json())
        .then(data => {
            console.log(data,"datatatata")
            if (data.status === 'success') {
                setSuccessMessage('Selection submitted successfully!');
                fetchUserSelections();
                setMessageColor('');
            } else if (data.status === 'info') {
                let message;
            switch (data?.matchData?.result) {
                case 0:
                    message = 'You have already selected a team. Match not Playing Yet!';
                    setMessageColor('green');
                    break;
                case 1:
                    message = 'Sorry, your team lost. You are out of the pool';
                    setMessageColor('red');
                    break;
                case 2:
                    message = 'Your team has tied! You can continue to the next week.';
                    setMessageColor('green');
                    break;
                case 3:
                    message = 'Congratulations! Your team has won. You can continue to the next week once started!';
                    setMessageColor('green');
                    break;
                default:
                    message = 'Unknown result.';
            }
                setSuccessMessage(message);
            } else {
                setErrorMessage(data.message);
                setSuccessMessage('');
            }
        })
        .catch(error => {
            setErrorMessage('Error submitting selection. Please try again.');
            //console.error('Error submitting selection', error);
        });
    };

    const toggleLeaderboard = () => {
        if (!showLeaderboard) {
        }
        setShowLeaderboard(prevState => !prevState);
    };
    


    return (
        <>
            <Header />
            <div className="background-image desktop-backimg surviour-back">
            <div className='survivor-top'>
                <div className='container'>
                    <div className='pool-heading'>
                        <h2>Survivor Pool</h2>
                        <h5>Select a team to start Pool!</h5>
                    </div>
                </div>
            </div>

            <div className="poll-section">
                <div className="container">
                <div style={{ color: messageColor }}>
                    {errorMessage && (
                        <p className="error-message">{errorMessage}</p> 
                    )}
                    {successMessage && (
                        <div style={{ color: messageColor, textAlign:'center' }}>{successMessage}</div>  
                    )}
                    {selectionMessage && (  
                        <p className="selection-message">{selectionMessage}</p>
                    )}
                    </div>
                    {/* Week filter dropdown */}
                    <div className="week-filter">
                        <label htmlFor="week">Select Week: </label>
                        <div class="select-week">
                        <select id="week" value={selectedWeek} onChange={handleWeekChange}>
                            {nflWeeks.map(week => (
                                <option key={week.week} value={week.week}>
                                    Week {week.week}
                                </option>
                            ))}
                        </select>
                        </div>
                        <button className='leader-btn' onClick={toggleLeaderboard}>
                                {showLeaderboard ? "Back to Week" : "Leaderboard"}
                        </button>
                    </div>
                    {showLeaderboard ? (
                            <Leaderboard/>
                        ) : (
                    filteredMatches.length > 0 ? (
                        <div className="poll-matchups">
                            {filteredMatches.map((team) => {
                                
                                const selectedTeamForMatch = selectedTeams[team.id];

                                return (
                                    <div key={team.id}
                                     className="poll-matchup"
                                     >
                                        <div className='teams'>
                                            <p className='clubs'>{team.home_team} @ {team.away_team}</p>
                                            <p className='date-day'>{team.match_date},{team.match_time}</p>
                                        </div>
                                        <div className="team-row">
                                            <Link 
                                                to="#" 
                                                onClick={(e) => {
                                                    e.preventDefault(); 
                                                    handlePoll(team.home_team, team.id, team.match_date);
                                                }}
                                                className={`link ${selectedTeamForMatch === team.home_team ? 'selected' : ''}`}
                                            >
                                                <div className="team-logo-wrapper">
                                                    <img
                                                        loading="lazy"
                                                        src={`images/${team.home_team}.svg`}
                                                        alt={`${team.home_team} logo`}
                                                        className="team-logo"
                                                        width={32}
                                                        height={32}
                                                    />
                                                </div>
                                                <div className="team-names" style={{ marginLeft: '5px' }}>
                                                    {team.home_team}
                                                </div>
                                            </Link>
                                            <span className="vs">@</span>
                                            <Link 
                                                to="#" 
                                                onClick={(e) => {
                                                    e.preventDefault(); // Prevent default anchor behavior
                                                    handlePoll(team.away_team, team.id, team.match_date);
                                                }}
                                                className={`link ${selectedTeamForMatch === team.away_team ? 'selected' : ''}`}
                                            >
                                                <div className="team-logo-wrapper">
                                                    <img
                                                        loading="lazy"
                                                        src={`images/${team.away_team}.svg`}
                                                        alt={`${team.away_team} logo`}
                                                        className="team-logo"
                                                        width={32}
                                                        height={32}
                                                    />
                                                </div>
                                                <div className="team-names" style={{ marginLeft: '5px' }}>
                                                    {team.away_team}
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="date-team">
                                            <p className="matchup-date">{team.match_day}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <p>No matches available for this week.</p>
                    )
                )}
                </div>
            </div>
            </div>
            <Footer />
        </>
    );
};

export default SurvivorPool;
