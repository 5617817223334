import React, { useState ,useEffect} from 'react';
import { Link, useLocation,Navigate , useNavigate} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import config from '../config'
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import "./future.css"

// Load your publishable key
const stripePromise = loadStripe('pk_test_51Po9er2NT4UBHQr57LnDoFDTT42xscNTr5SvWL0wCINzQdIreG7O7qd0GU43EjoOqkyQ268hBj0Dq27EVzDMFWaN008zAuGXmA');
const CheckoutForm = ({ tierName, price, hasCartItem, discountedPrice, appliedPromoCode}) => {

  const stripe = useStripe();
  const elements = useElements();
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const parsedPrice = parseFloat(price);
  const parsedDiscountedPrice = parseFloat(discountedPrice);


  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedFirstName = localStorage.getItem('firstName');
    const storedEmail = localStorage.getItem('email');
    const storedPhone = localStorage.getItem('phoneNumber');
    if (token && storedFirstName) {
      setFirstName(storedFirstName);
    }
    if (storedEmail) {
      setEmail(storedEmail);
    }
    if (storedPhone) {
      setPhone(storedPhone);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
  
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
  
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: firstName, // You can replace this with the real name
        email: email, // Using the email from local storage
        phone: phone,
      },
    });
  
    if (error) {
      setErrorMessage(error.message);
      setIsProcessing(false);
    } else {
      // Handle successful payment here, e.g., sending paymentMethod.id to your backend
      console.log('Payment Method created:', paymentMethod);
      
      // Define your return URL
      const returnUrl = 'http://localhost:3000/'; // Change to your success page
      
      const amountToCharge = discountedPrice < price ? discountedPrice : price;

      // Example: POST to your backend to process the payment
      const response = await fetch(`${config.baseUrl}/api/charge`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          amount: amountToCharge * 100, // Stripe takes the amount in cents
          paymentMethodId: paymentMethod.id,
          firstName,
          tierName,
          email,
          phone,
          returnUrl, // Pass returnUrl here
        }),
      });

      console.log("Request Payload: ", {
        amount: price * 100,
        paymentMethodId: paymentMethod.id,
        name: firstName,
        returnUrl,
      });

      const paymentResult = await response.json();
      
      console.log(paymentResult,"result")

      if (paymentResult.error) {
        setErrorMessage(paymentResult.error.message);
        setIsProcessing(false);
      } else {
        // Payment was successful, set the paymentSuccess state to true
        setPaymentSuccess(true);
        localStorage.removeItem('cart');
      }
    }
  };

  if (paymentSuccess) {
    return <Navigate to="/thankyou" />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <h5>Payment Method</h5>
      
      <div className='checkout-form'>
        <div className='row'>
          <div className='col-md-12'>
            <CardElement />
          </div>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <div className='line-card'></div>
        <div className='total-part'>
          <table>
            <tbody>
              <tr>
                <td>Subtotal</td>
                <td style={{ textAlign: 'right'}}>${hasCartItem ? parsedPrice : 0}</td>
              </tr>
              {
                discountedPrice < price && (
                  <tr>
                    <td>Discount Amount</td>
                    <td style={{textAlign:'right'}}>${hasCartItem ? (parsedPrice - parsedDiscountedPrice).toFixed(2) : '0.00'}</td>
                  </tr>
                )
              }
              <tr>
                <td>Total</td>
                <td style={{ textAlign: 'right'}}>${hasCartItem ? parsedDiscountedPrice.toFixed(2) : 0}</td>
              </tr>
            </tbody>
          </table>
          {/* {discountedPrice < price && (
            <div className="discount-message">
              <p>Applied Promo Code: {appliedPromoCode}</p> 
            </div>
          )}
           */}
            <div className='Surviour-btn checkout-button'>
            <button type="submit" disabled={!stripe || isProcessing || !hasCartItem}>
              {isProcessing ? 'Processing...' : 'Checkout'}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const CheckOut = () => {
  const location = useLocation();
  const { tierName, price } = location.state || { tierName: 'Tier not selected', price: 0 }; // Handle missing state
  const [hasCartItem, setHasCartItem] = useState(true); // State to track cart item presence
  const [promoCode, setPromoCode] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(price);
  const [errorMessage, setErrorMessage] = useState('');
  const [appliedPromoCode, setAppliedPromoCode] = useState('');
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  

  useEffect(() =>{
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login'); // Redirect to login page if not authenticated
    } else {
      setIsAuthenticated(true); // Set authentication state if token exists
    }

    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(storedCart);
    setHasCartItem(storedCart.length > 0);

    // Calculate the total discounted price
    const totalAmount = storedCart.reduce((total, item) => total + item.price, 0);
    setDiscountedPrice(totalAmount);
    
  },[])

  const handleRemoveCartItem = () => {
    setHasCartItem(false); // Remove the cart item
    setDiscountedPrice(price); // Reset the discounted price to original price
    setAppliedPromoCode(''); // Clear the applied promo code
    setIsPromoApplied(false);
    setPromoCode('');
    localStorage.setItem('hasCartItem', 'false');
    localStorage.removeItem('cart');
    window.location.reload();
  };

  const handleApplyPromocode = async () => {
    if (!hasCartItem) {
      setErrorMessage('No item in the cart. Please add items to apply a promo code.');
      return;
    }
    setErrorMessage('');
    try {
      const response = await fetch(`${config.baseUrl}/promocode`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          promocode: promoCode,
          totalAmount: discountedPrice,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        if (data.status === 'active') {
          const discountAmount = (data.discountPercentage / 100) * discountedPrice;
          const newDiscountedPrice = discountedPrice - discountAmount;

          setDiscountedPrice(newDiscountedPrice);
          setAppliedPromoCode(promoCode);
          setIsPromoApplied(true);
          setErrorMessage(''); // Clear error message on success
        } else {
          setErrorMessage('Cannot Apply! Promo code not active!'); // Show inactive message
          setIsPromoApplied(false);
        }
      } else {
        setErrorMessage(data.msg || 'Unable to apply promo code. Please try again later.');
      }
    } catch (error) {
      console.error('Error applying promo code:', error);
      setErrorMessage('An error occurred while applying the promo code. Please try again later.');
    }
  };

  const tierNameMapping = {
    Tier1 : 'Tier 1 Membership',
    Tier2 : 'Tier 2 Membership',
    Tier3 : 'Tier 3 Membership'
  }

  const handleRemovePromoCode = () => {
    setPromoCode('');
    // setDiscountedPrice(price); 
    setDiscountedPrice(cartItems.reduce((total, item) => total + item.price, 0)); 
    setAppliedPromoCode(''); 
    setIsPromoApplied(false); 
    setErrorMessage(''); 
  };

  return (
    <>
      <Header />
      <section className='checkout-page'>
      <div className='container'>
        <div className='continue-shoping'>
          <Link to="/"><h4><img src="images/checkoutarrow.png" alt=""/><span>CONTINUE SHOPPING</span></h4></Link>
        </div>
        <div className='car-heading'>
          <h2>Your Cart</h2>
        </div>
        <div className='checkoutdetail'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='left-part-checkout'>
                <h5>SHOPPING CART</h5>
                {hasCartItem && <p>You have {cartItems.length} item{cartItems.length > 1 ? 's' : ''} in your cart.</p>}
                {hasCartItem ? (
                  cartItems.map((item, index) => (
                    <div key={index} className='tire-membership'>
                      <div className='left-tier'>
                      <h4>{tierNameMapping[item.tierName] || 'Tier not selected'}</h4>
                      </div>
                      <div className='right-tier'>
                        <h4>
                          ${item.price || 0}
                          <img 
                            src="images/checkoutdelete.png" 
                            style={{ cursor: 'pointer' }} 
                            onClick={() => handleRemoveCartItem(index)} 
                            alt=""
                          />
                        </h4>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-cart-item">
                    <h4>No Cart Item Added</h4>
                  </div>
                )}
                {hasCartItem && (
                  <div className='promo-code'>
                    <input 
                      type="text" 
                      value={promoCode} 
                      onChange={(e) => setPromoCode(e.target.value)} 
                      placeholder="Enter Promo Code"
                    />
                    {isPromoApplied && (
                      <span className="remove-promo" onClick={handleRemovePromoCode}>&times;</span>
                    )}
                    <div className='Surviour-btn promo-button'>
                      <button
                        type="button"
                        onClick={handleApplyPromocode}
                        style={{
                          backgroundColor: isPromoApplied ? 'green' : '',
                          color: isPromoApplied ? 'white' : '',
                        }}
                      >
                        {isPromoApplied ? 'Applied' : 'Apply'}
                      </button>
                    </div>
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                  </div>
                )}
                <div className='bagels-locksimgcheckout'>
                  <img src="images/checkbagleslocks.png" alt=""/>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='right-part-checkout'>
                <Elements stripe={stripePromise}>
                  <CheckoutForm 
                    tierName={cartItems.length > 0 ? cartItems[0].tierName : ''} 
                    price={cartItems.length > 0 ? cartItems[0].price : 0}
                    hasCartItem={hasCartItem} 
                    discountedPrice={discountedPrice} 
                    appliedPromoCode={appliedPromoCode}
                  />
                </Elements>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      <Footer />
    </>
  );
};

export default CheckOut;