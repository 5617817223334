import React, { useEffect, useState } from 'react';
import './homepage.css'
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import ProfileTabs from '../Components/Homepage/ProfileTabs';
import VideoShow from '../Components/Homepage/VideoShow';
import CustomTabs from '../Components/Homepage/CustomTabs';
import Abouts from '../Components/Homepage/Abouts';
import Serving from '../Components/Homepage/serving';
import Model from '../Components/Homepage/Model';

const Homepage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');

    
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false); // If no token, user is not logged in
    }
  }, []);

  

  return (
    <>
    <Header/>
    <Abouts/>
    <Serving/>
    <ProfileTabs/>
    <VideoShow/>
    {isLoggedIn && <CustomTabs />}
    <Footer/>
    <Model/>
    </>
  )
}

export default Homepage
