import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';


const Account = () => {
    const [userData, setUserData] = useState([]);
    const [membership, setMembership] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [previewUrl, setPreviewUrl] = useState(null);


    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (!token) {
            navigate('/login');
            return;
        }

        fetchUserData(token);
        fetchMembershipData(token);
    }, [navigate]);

    const fetchUserData = async (token) => {
        try {
            const response = await fetch(`${config.baseUrl}/welcome`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();
            setUserData(data.data);
            fetchMembershipData(token, data.data.id);
        } catch (error) {
            setError('Failed to fetch data');
            console.error('Error fetching user data:', error);
        }
    };
        console.log(userData, "user data")

    const fetchMembershipData = async (token, user_id) => {
        try {
            const response = await fetch(`${config.baseUrl}/membership/${user_id}`, {
                method: 'GET',
                headers: { 'Content-Type': 'Application/json', 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                const data = await response.json();
                if (data.status === 'success') {
                    setMembership(data.data);
                }
            } else {
                console.error('Failed to fetch membership data');
            }
        } catch (error) {
            console.error('Error fetching membership data:', error);
        }
    };

        console.log(membership, "membership")

     const [selectedFile, setSelectedFile] = useState();

    // const handleFileChange = (e) => {
    //     if (e.target.files && e.target.files.length > 0) {
    //         setSelectedFile(e.target.files[0]);
    //     }
    // };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            const fileUrl = URL.createObjectURL(file);  // Generate a temporary URL for the image preview
            setPreviewUrl(fileUrl);  // Set the preview URL in state
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        const token = localStorage.getItem('token'); // Get the token here
        const user_id = userData.id; // Use the user ID from userData
        if (!selectedFile) {
            console.error("No file selected");
            Swal.fire('Error', 'Please select an image file to upload.', 'error'); // Show an error message
            return;
        }
    
        const formData = new FormData();
        formData.append('image', selectedFile);// Ensure this matches the backend API
    
        try {
            const response = await fetch(`${config.baseUrl}/user_image/update/${user_id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });
    
            const data = await response.json();
            if (response.ok) {
                console.log('Profile updated successfully', data);
                setSuccessMessage('Profile updated successfully!');
                // Optionally, fetch the user data again to reflect changes
                fetchUserData(token);
                Swal.fire({
                    title: 'Success!',
                    text: 'Profile photo update successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            } else {
                console.error('Error updating profile', data.msg);
                Swal.fire('Error', data.msg || 'Failed to update profile.', 'error'); // Show error message
            }
        } catch (err) {
            console.error('Error:', err);
            Swal.fire('Error', 'An unexpected error occurred. Please try again.', 'error');
        }
    };
    
    const handleRemoveProfilePhoto = async () => {
        const token = localStorage.getItem('token'); // Get the token here
        const user_id = userData.id; // Use the user ID from userData
    
        try {
            const response = await fetch(`${config.baseUrl}/user_image/remove/${user_id}`, { // Update the endpoint here
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            const data = await response.json();
            if (response.ok) {
                console.log('Profile photo removed successfully', data);
                setSuccessMessage('Profile photo removed successfully!'); // Set success message
                setPreviewUrl(null); // Clear the preview
                fetchUserData(token); // Optionally, fetch the user data again to reflect changes
                Swal.fire({
                    title: 'Success!',
                    text: 'Profile photo removed successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            } else {
                console.error('Error removing profile photo', data.msg);
                Swal.fire('Error', data.msg || 'Failed to remove profile photo.', 'error'); // Show error message
            }
        } catch (err) {
            console.error('Error:', err);
            Swal.fire('Error', 'An unexpected error occurred. Please try again.', 'error'); // Show unexpected error message
        }
    };
    
    
    const handlePayment = () => {
        navigate("/membership")
    }

    const getCurrentTier = () => {
        if (Array.isArray(membership) && membership?.length > 0) {
            //  console.log("Membership data", membership);
            const currentMemberShip = membership[membership.length - 1];
            // console.log("Current Membership", currentMemberShip);
            return currentMemberShip;
        }
        return null;
    };

    const getProductDescription = (tierName) => {
        switch (tierName) {
            case 'Tier1':
                return 'BAGELS';
            case 'Tier2':
                return 'BAGELS WITH SPREAD';
            case 'Tier3':
                return 'BAGEL, SPREAD & LOX';
            default:
                return `You don't have any plan`;
        }
    }

    const formatAmount = (amount) => {
        return `$${(amount / 100).toFixed(2)}`
    }
    const captalizeFirstLetter = (string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    const getButtonText = (tierName) => {
        return 'UPGRADE PLAN'
    }
    const handleUpgrade = () => {
        navigate('/membership'); // Navigate to the /membership page
    };
   
    const handleGetSubscription = () => {
        navigate('/membership'); // Navigate to the membership page to get a subscription
    };

    if (!userData) {
        navigate('/login');
        return null; // Redirects if not logged in
    }
    // This function will format the created_at date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        });
    };
    const currentTier = getCurrentTier();
    const tierDescription = currentTier ? getProductDescription(currentTier.tier_name) : 'You don\'t have any plan';
    console.log(currentTier, "current tier")
    const addOneMonth = (date) => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + 1); // Add one month
        return newDate;
    };

    return (
        <>
            <Header />
            <section className='signup-form'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='commonTwoBlk upload_blk'>
                                <div className='user-photo'>
                                    {previewUrl ? (
                                        <img 
                                            src={previewUrl}
                                            alt='Profile Image'
                                            height="100px"
                                            width="100px"
                                            style={{ 
                                                border: '2px solid #ccc',   
                                                borderRadius: '50px'        
                                            }} 
                                        />
                                    ) :(
                                        
                                    <img
                                    src={`${config.baseUrl}/uploads/${userData.image ? userData.image : 'default-image.png'}`} // Ensure image path exists
                                    alt={userData.first_name || 'Default Profile'}
                                    height="100px"
                                    width="100px"  
                                    style={{ 
                                        border: '2px solid #ccc',   
                                        borderRadius: '50px'        
                                    }}     
                                    />
                                    )}  
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="box">
                                        <input
                                             id="file-2"
                                            className="inputfile inputfile-2"
                                            type="file" 
                                            name="image" 
                                            accept="image/*" 
                                            multiple={false} 
                                            onChange={handleFileChange}
                                        />
                                        <label htmlFor="file-2">
                                            <img src='images/file-icon.png' alt="Upload Icon" />
                                            <span>Upload Your Photo</span>
                                        </label>
                                    </div>
                                    <button onClick={handleSubmit} className='btn-fill'>Update Profile Photo</button>

                                </form>
                                <button onClick={handleRemoveProfilePhoto} className='btn-fill'>Remove Profile Photo</button>
                                <h2>{userData.first_name}</h2>
                                <p>Joined {formatDate(userData.created_at)}</p>
                            </div>
                        </div>
                    </div>

                    <div className='account-sub'>
                        <div className='row'>
                            <div className='col-md-6 one1'>
                                <div className='commonTwoBlk'>
                                    <h2 className='_heading'>ACCOUNT</h2>
                                    <div className='_blk'>
                                        <p className='label'>Username:</p>
                                        <p>{userData.first_name}</p>
                                    </div>
                                    <div className='_blk'>
                                        <p className='label'>Email:</p>
                                        <p>{userData.email}</p>
                                    </div>
                                    <div className='_blk'>
                                        <p className='label'>Membership:</p>
                                        <p>{currentTier ? currentTier.tierName : 'No membership'}</p>

                                    </div>
                                    {/* {currentTier && currentTier.tierName !== 'Tier3' && (
                                        <button type='type' onClick={handleUpgrade} className='btn-fill'>UPGRADE YOUR PLAN</button>
                                    )} */}
                                     {currentTier ? (
                                        currentTier.tierName !== 'Tier3' && (
                                            <button type='button' onClick={handleUpgrade} className='btn-fill'>UPGRADE YOUR PLAN</button>
                                        )
                                    ) : (
                                        <button type='button' onClick={handleGetSubscription} className='btn-fill'>SUBSCRIBE TO A PLAN</button>
                                    )}
                                </div>
                            </div>
                            <div className='col-md-6 one'>
                                <div className='commonTwoBlk'>
                                <h2 className='_heading'>Payment</h2>
                                {currentTier ? (
                                    <>
                                        <div className='_blk'>
                                            <p className='label'>Last Payment:</p>
                                            <p>{formatDate(currentTier.created_at)}</p>
                                        </div>
                                        <div className='_blk'>
                                            <p className='label'>Next Payment:</p>
                                            <p>{formatDate(addOneMonth(currentTier.created_at))}</p>
                                        </div>
                                        <div className='_blk'>
                                            <p className='label'>Payment Method:</p>
                                            <p>Stripe</p>
                                        </div>
                                        {/* Uncomment if you want to allow users to upgrade their payment method */}
                                        {/* <button type='type' onClick={handlePayment} className='btn-fill'>UPGRADE PAYMENT METHOD</button> */}
                                    </>
                                ) : (
                                    <div>
                                        <h3>You don't have any active subscription</h3>
                                        <p>To access member benefits, please subscribe to a plan.</p>
                                        <button type='button' onClick={handleGetSubscription} className='btn-fill'>SUBSCRIBE TO A PLAN</button>
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                        {tierDescription !== "You don't have any plan" && (
                            <div className='cancel-membership'>
                                <div className='col-md-12 text-center'>
                                    <Link className='anchor'>Cancel Membership</Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <Footer />

        </>
    );
}



export default Account;
