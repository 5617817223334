import React, { useEffect, useState } from 'react';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Dashboard = () => {
    const [userData, setUserData] = useState({ first_name: '', last_name: '', email: '', phoneno: '' });
    const [membership, setMembership] = useState(null);
    const [passwordData, setPasswordData] = useState({ old_password: '', new_password: '', confirm_password: '' });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [passwordErrors, setPasswordErrors] = useState({
        matchError: false,
        lengthError: false,
        oldPasswordError: false,
    });

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        fetchUserData(token);
        fetchMembershipData(token);
    }, [navigate]);

    const fetchUserData = async (token) => {
        try {
            const response = await fetch(`${config.baseUrl}/welcome`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();
            setUserData(data.data);
        } catch (error) {
            setError('Failed to fetch data');
            console.error('Error fetching user data:', error);
        }
    };
    console.log(userData, "userdata")
    const fetchMembershipData = async (token) => {
        try {
            const response = await fetch(`${config.baseUrl}/membership`, {
                method: 'GET',
                headers: { 'Content-Type': 'Application/json', 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                const data = await response.json();
                if (data.status === 'success') {
                    setMembership(data.data);
                }
            } else {
                console.error('Failed to fetch membership data');
            }
        } catch (error) {
            console.error('Error fetching membership data:', error);
        }
    };

    const handleLogout = async () => {
        try {
            const response = await fetch(`${config.baseUrl}/logout`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            });
            if (response.ok) {
                localStorage.removeItem('token');
                navigate('/');
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('An error occurred during logout:', error);
        }
    };

    const handleChangePassword = (e) => {
        e.preventDefault();
        const { old_password, new_password, confirm_password } = passwordData;

        if (new_password.length < 6) {
            setPasswordErrors({ ...passwordErrors, lengthError: true });
            return;
        }
        if (new_password !== confirm_password) {
            setPasswordErrors({ ...passwordErrors, matchError: true });
            return;
        }

        const token = localStorage.getItem('token');
        if (token) {
            fetch(`${config.baseUrl}/profile/change-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ old_password, new_password }),
            })
                .then((response) => response.json())
                .then((data) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Password Updated Successfully',
                        timer: 2500,
                        showConfirmButton: false,
                    });
                    if (!data.success) {
                        console.log('Failed to change password. Please try again.');
                    }
                })
                .catch((error) => console.error('Error changing password:', error));
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const token = localStorage.getItem('token');
    
        if (token) {
            fetch(`${config.baseUrl}/profile/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(userData), // Send the entire userData object as JSON
            })
                .then((response) => response.json())
                .then((data) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Profile Updated Successfully',
                        timer: 2500,
                        showConfirmButton: false,
                    });
                    setSuccessMessage(data.success ? 'Profile updated successfully!' : 'Failed to update profile. Please try again.');
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error updating profile:', error);
                    setLoading(false);
                });
        }
    };
    

    const getCurrentTier = () => {
        return Array.isArray(membership) && membership.length > 0 ? membership[membership.length - 1] : null;
    };

    const formatAmount = (amount) => `$${(amount / 100).toFixed(2)}`;
    const capitalizeFirstLetter = (str) => str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';

    const getButtonText = (tierName) => (tierName === 'Tier3' ? 'CHANGE PLAN' : 'UPGRADE PLAN');

    const currentTierData = getCurrentTier();

    if (!userData) {
        navigate('/login');
        return null; // Redirects if not logged in
    }

    return (
        <>
            <Header />
            <section className='dashborad'>
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <div className='dashborad-name'>
                                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Dashboard</button>
                                    <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</button>
                                    <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Change Password</button>

                                    <button className="nav-link" onClick={handleLogout} id="v-pills-settings-tab" >Logout</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-9">
                            <div className='dashboardcontent'>
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                        <h4>Hello {userData.first_name}</h4>
                                        <h4>Current Plan</h4>
                                        <div className='memnbership-plan1'>
                                            <div className="meber-bagel1"><div className="left-bagel1"><h3>BAGEL</h3><div className="dot-part"></div><h3><s>{formatAmount(currentTierData?.amount)}<span className="deskmon">Monthly</span><span className="mobmon">/mo</span></s></h3></div></div>
                                            <div className="Surviour-btn Update-plan"><a href="/membership">{getButtonText(currentTierData?.tierName)}</a></div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                        <h4>Profile</h4>
                                        <div className="signup-form-sub profile-form">
                                            <form onSubmit={handleFormSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <input type="text" placeholder="First Name" value={userData.first_name || ''}
                                                            onChange={(e) => setUserData({ ...userData, first_name: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input type="text" placeholder="Last Name" value={userData.last_name || ''}
                                                            onChange={(e) => setUserData({ ...userData, last_name: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <input type="text" placeholder="Email" value={userData.email || ''}
                                                            onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input type="text" placeholder="Phone" value={userData.phoneno || ''}
                                                            onChange={(e) => setUserData({ ...userData, phoneno: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input type="submit" value="Submit" />
                                                    </div>
                                                </div>
                                            </form></div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                        <h4>Change Password</h4>
                                        <div className='Chnage-password'>
                                            <form onSubmit={handleChangePassword}>
                                                <div className='inputDiv'>
                                                    <label className="inputLabel" for="password">Current Password</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Current Password"
                                                        value={passwordData.old_password}
                                                        onChange={(e) => setPasswordData({ ...passwordData, old_password: e.target.value })}
                                                        required
                                                    />
                                                </div>
                                                <p>Password must be at least six characters.</p>
                                                <div className="inputDiv">
                                                    <label className="inputLabel" for="password">New Password</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="New Password"
                                                        value={passwordData.new_password}
                                                        onChange={(e) => setPasswordData({ ...passwordData, new_password: e.target.value })}
                                                        required
                                                    />
                                                </div>
                                                <div className="inputDiv">
                                                    <label className="inputLabel" for="confirmPassword">Confirm Password</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Confirm Password"
                                                        value={passwordData.confirm_password}
                                                        onChange={(e) => setPasswordData({ ...passwordData, confirm_password: e.target.value })}
                                                        required
                                                    />
                                                    {passwordErrors.matchError && <p>Passwords do not match</p>}
                                                    {passwordErrors.lengthError && <p>Password must be at least 6 characters long</p>}
                                                    <button type="submit" className="btn">Submit</button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                        <h4>Settings Tab</h4>
                                        <p>This is the content for the Settings tab.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Dashboard;
