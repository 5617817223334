import React, { useState, useRef, useEffect } from 'react';
import './future.css';
import { Link } from 'react-router-dom';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import config from '../config';
import parse from 'html-react-parser';

const Future = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [topList, setTopList] = useState([]); // State to hold API data
    const [selectedWeek, setSelectedWeek] = useState('1'); // State for selected week
    const sliderRef = useRef(null);
    const [articleList, setArticleList] = useState([]);
    const [expandedIndex, setExpandedIndex] = useState(null); // Track expanded state for each article

    const updateTableVisibility = (slideIndex) => {
        const startIndex = slideIndex;
        const endIndex = startIndex + 4; // Display 5 items (0-indexed)

        document.querySelectorAll('.table-column').forEach(column => {
            const columnIndex = parseInt(column.getAttribute('data-column-index'), 10);
            if (columnIndex >= startIndex + 1 && columnIndex <= endIndex + 1) {
                column.style.display = 'table-cell'; // Use 'table-cell' for table columns
            } else {
                column.style.display = 'none';
            }
        });
    };

    const handleAfterChange = (current) => {
        setCurrentSlide(current);
        updateTableVisibility(current);
    };

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: handleAfterChange,
        arrows: false, // We'll control the navigation with buttons
    };

    // Fetch data from API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/list-top`);
                const data = await response.json();
                setTopList(data.data); // Store the API data in state

            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/list-article`);
                const data = await response.json();
                setArticleList(data.data);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, []);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const toggleReadMore = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const getShortText = (text, isExpanded) => {
        const wordLimit = 20;
        const words = text.split(' ');
        if (words.length <= wordLimit || isExpanded) {
            return parse(text); // Use parse to render HTML safely
        }
        return parse(words.slice(0, wordLimit).join(' ') + '...'); // Parse the truncated text as well
    };
    const handlePrev = () => {
        sliderRef.current.slickPrev();
    };

    const handleNext = () => {
        sliderRef.current.slickNext();
    };

    const handleWeekChange = (e) => {
        setSelectedWeek(e.target.value); // Update the selected week
    };

    // Filter topList by selected week_name
    const filteredTopList = topList.filter(item => item.week_name === selectedWeek);

    return (
        <>
            <Header />
            <section className="circa-part">
                <div className="container">
                    <div className="circa-heading circa-top1">
                        <h2>Circa Contest Weekly Spread Picks</h2>
                        <div className="circaselect-box">
                        <select className="select-box" value={selectedWeek} onChange={handleWeekChange}>
                                <option value="1">Week 1</option>
                                <option value="2">Week 2</option>
                                <option value="3">Week 3</option>
                                <option value="4">Week 4</option>
                                <option value="5">Week 5</option>
                                <option value="6">Week 6</option>
                                <option value="7">Week 7</option>
                                <option value="8">Week 8</option>
                                <option value="9">Week 9</option>
                                <option value="10">Week 10</option>
                                <option value="11">Week 11</option>
                                <option value="12">Week 12</option>
                                <option value="13">Week 13</option>
                                <option value="14">Week 14</option>
                                <option value="15">Week 15</option>
                                <option value="16">Week 16</option>
                                <option value="17">Week 17</option>
                                <option value="18">Week 18</option>
                            </select>
                        </div>
                    </div>
                    {filteredTopList.length > 0 ? (
                        filteredTopList.map((item, index) => (
                            <div key={index} className="circa-box">
                                <div className="circa-box1">
                                    <div className="circalleft">
                                        <div className="cicraleftt">
                                            <div className="cicralefttt">
                                                <img src={`${config.imageUrl}/${item.home_image}`} alt=""/>
                                            </div>
                                            <div className="cicracontent">
                                                <h4>{item.home_team}</h4>
                                            </div>
                                        </div>
                                        <div className="cicraleftt">
                                            <div className="cicralefttt">
                                                <img src={`${config.imageUrl}/${item.away_image}`} alt=""/>
                                            </div>
                                            <div className="cicracontent">
                                                <h4>{item.away_team}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="circalcenter">
                                        <img src="images/rectanglecirco.png" alt="" />
                                    </div>
                                    <div className="circalright">
                                        <h3>{item.winning_team}  {item.winning_value}</h3>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="nodata">No data available for the selected week.</p>
                    )}
                </div>
                <section className="survvivor-strategy-blk">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="circa-heading Strategy-Hub">
                                    <h2>Survivor Strategy Hub</h2>
                                </div>
                                <div className="membership-heading unlock">
                                    <p>
                                        Unlock the keys to survivor success with Wade's Survivor Grid,
                                        insightful podcasts, and expert articles. Whether you're new or
                                        experienced, our resources will help you navigate the season with
                                        confidence.
                                    </p>
                                </div>
                                <div className="grid-postcard-blk">
                                    <div className="common-blk">
                                        <div className="imgblk">
                                            <img src="images/survivor-grid.png" alt="" />
                                        </div>
                                        <div className="Surviour-btn upgrade-btn grid-btn-circa">
                                            <Link to="/survivor-hub">VIEW SURVIVOR GRID</Link>
                                        </div>
                                    </div>
                                    <div className="common-blk">
                                        <div className="imgblk">
                                            <img src="images/poscast.png" alt="" />
                                        </div>
                                        <div className="Surviour-btn upgrade-btn">
                                            <Link to="/wade-deli">LISTEN TO THE PODCAST</Link>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="text-center">Stay Informed: Read the Latest </h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="article-headline-blk">
                    <div className="container">
                        <div className="row">
                            <Slider {...settings} ref={sliderRef}>
                                {articleList.map((item, index) => (
                                    <div key={index} className="col-md-12">
                                        <div className="article-blk">
                                            <h5>{formatDate(item.created_at)}</h5>
                                            <h2 className="heading article-heading1">{item.heading}</h2>
                                            <p>
                                                {getShortText(item.paragraph, expandedIndex === index)}
                                                <button
                                                    onClick={() => toggleReadMore(index)}
                                                    className="read-more-less-btn"
                                                >
                                                    {expandedIndex === index ? "Read Less" : "Read More"}
                                                </button>
                                            </p>
                                        </div>
                                        <div className="pre-next-button">
                                            <button type="button" className="outline-btn" onClick={handlePrev}>
                                                <i className="fa fa-caret-left" aria-hidden="true" /> PREVIOUS
                                            </button>
                                            <button type="button" className="outline-btn" onClick={handleNext}>
                                                NEXT <i className="fa fa-caret-right" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                ))}

                            </Slider>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </>
    )
}

export default Future
