import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import config from '../config';
import parse from 'html-react-parser';

const WadeBakery = () => {
    const [pickList, setPickList] = useState([]);
    const [articleList, setArticleList] = useState([]);
    const sliderRef = useRef(null);
    const [betList, setBetList] = useState([]);
    // const [currentSlide, setCurrentSlide] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [podcastList, setPodcastList] = useState([]);
    const [showMoreArticles, setShowMoreArticles] = useState(false);
    const [selectedWeek, setSelectedWeek] = useState('1');
    const [betItemsToShow, setBetItemsToShow] = useState(5);
    const [showBetLoadMore, setShowBetLoadMore] = useState(false);
    const [expandedArticle, setExpandedArticle] = useState({});
    const [loading, setLoading] = useState(true);
    
    // Combined useEffect for all data fetching

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [pickResponse, articleResponse, betResponse, podcastResponse] = await Promise.all([
                    fetch(`${config.baseUrl}/list-pick`),
                    fetch(`${config.baseUrl}/list-article`),
                    fetch(`${config.baseUrl}/list-bet`),
                    fetch(`${config.baseUrl}/list-podcast`),
                ]);

                const pickData = await pickResponse.json();
                const articleData = await articleResponse.json();
                const betData = await betResponse.json();
                const podcastData = await podcastResponse.json();

                setPickList(pickData.data);
                setArticleList(articleData.data);
                setBetList(betData.data);
                setPodcastList(podcastData.data);
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    
    const updateTableVisibility = (slideIndex) => {
        const startIndex = slideIndex;
        const endIndex = startIndex + 4; // Display 5 items (0-indexed)

        document.querySelectorAll('.table-column').forEach(column => {
            const columnIndex = parseInt(column.getAttribute('data-column-index'), 10);
            if (columnIndex >= startIndex + 1 && columnIndex <= endIndex + 1) {
                column.style.display = 'table-cell'; // Use 'table-cell' for table columns
            } else {
                column.style.display = 'none';
            }
        });
    };
    const handleAfterChange = (current) => {
        setCurrentSlide(current);
        updateTableVisibility(current);
      };

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true, // Ensures arrows are shown
        afterChange: handleAfterChange, // Pass the handler here
        responsive: [
          {
            // tablet 
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              arrows: false,
              dots: true
            }
          },
          {
            // mobile portrait
            breakpoint: 479,
            settings: {
              slidesToShow: 1,
              arrows: false,
              dots: true
            }
          }
        ]
      };
    
      const handlePrevious = () => {
        sliderRef.current.slickPrev(); // Go to previous slide
    };

    const handleNext = () => {
        sliderRef.current.slickNext(); // Go to next slide
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const getImageUrl = (imagePath) => {
        return `${config.imageUrl}/${imagePath}`;
    };


    // const latestArticle = articleList.length > 0 ? articleList[0] : null;

    const filteredArticles = articleList.filter(item => item.status === 1); // Filter articles with status 1
    const latestArticle = filteredArticles.length > 0 ? filteredArticles[0] : null; // Get 

    const toggleReadMore = (index) => {
        setExpandedArticle((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // Toggle expanded state
        }));

    };

    const renderParagraph = (paragraph, index) => {
        const words = paragraph.split(' ');
        const isExpanded = expandedArticle[index];
        const limitedText = words.slice(0, 32).join(' '); // Show first 23 words
        const displayText = isExpanded ? paragraph : `${limitedText}...`;

        return (
            <>
                <p>
                    {parse(displayText)} {/* Parse the HTML content here */}
                </p>
                <div className="article-btn1 read_more">
                    <Link to="#" onClick={() => toggleReadMore(index)}>
                        {isExpanded ? 'Read Less' : 'Read More'}
                    </Link>
                </div>
            </>
        );
    };

    const toggleShowMoreArticles = () => {
        setShowMoreArticles((prevState) => !prevState);
    };

    const handleBetLoadMore = () => {
        setBetItemsToShow(betList.length);
        setShowBetLoadMore(false);
    };

    const handleBetLoadLess = () => {
        setBetItemsToShow(5);
        setShowBetLoadMore(true);
    };

    const handleWeekChange = (e) => {
        setSelectedWeek(e.target.value);
    };
    console.log(latestArticle, "article")

    // const filteredTopList = pickList.filter(item => item.week_name === selectedWeek);
    const filteredTopList = pickList.filter(item => item.week_name === selectedWeek && item.status === 1);

    const filterPodCast = podcastList.filter(item => item.status === 1);
    return (
        <>
            <Header />
            <section className="wade-kitchen">
                <div className='container'>

                </div>
            </section>

            <section className="more-article wade-article">
                <div className='wade_wrapper'>
                    <div className="wade-arcticle">
                        <div className="container">
                            <div className="latest-article">
                                <h4>LATEST ARTICLE</h4>
                            </div>
                        </div>
                    </div>
                    {latestArticle && (
                        <div className="wade-date">
                        <div className="container">
                            <div className="aub-article">
                                <div className="article-part">
                                    <div className="date-img">
                                        <div className="article-date">
                                            <h5>{formatDate(latestArticle.created_at)}</h5>
                                        </div>
                                        <div className="article-img">
                                            <img alt="" src="images/wadeartcile1.png" />
                                        </div>
                                    </div>
                                    <div className="article-img1">
                                        <img alt="" src={getImageUrl(latestArticle.image)} />
                                        <div className="article-heading">
                                            <h2 className='article-headingwade'> Article heading..{latestArticle.heading}</h2>
                                            {renderParagraph(latestArticle.paragraph)}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>

                    )}
                </div>
                <div className='wade-moreart'>
                    <div className="container">
                        <h2>More Articles</h2>
                        {/* Show the first article and toggle more articles on "Load More" click */}
                        {filteredArticles.length < 2 ? (
                          <p>No more articles to show.</p> // Message when no articles are available
                                ) : (
                                    // Show the first article and toggle more articles on "Load More" click
                                    filteredArticles.slice(1, showMoreArticles ? filteredArticles.length : 2).map((item, index) => (
                                        <div key={index} className="article-box">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="article-img1">
                                                        <img alt="" src={getImageUrl(item.image)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="article-content">
                                                        <h5>{formatDate(item.created_at)}</h5>
                                                        <h4>{item.heading}</h4>
                                                        {renderParagraph(item.paragraph, index + 1)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                        <div className="Surviour-btn load-morebtn wade-loadmore">
                            <Link to="#" onClick={toggleShowMoreArticles}>
                                {showMoreArticles ? 'Load Less' : 'Load More'}
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="circa-part1 foot-pick">
                <div className="container">
                    <div className="circa-heading wade-bakery">
                        <h2>Wade’s Weekly Football Picks</h2>
                        <div className="footbal-picks">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="circaselect-box">
                                        <select className="select-box" value={selectedWeek} onChange={handleWeekChange}>
                                            <option value="1">Week 1</option>
                                            <option value="2">Week 2</option>
                                            <option value="3">Week 3</option>
                                            <option value="4">Week 4</option>
                                            <option value="5">Week 5</option>
                                            <option value="6">Week 6</option>
                                            <option value="7">Week 7</option>
                                            <option value="8">Week 8</option>
                                            <option value="9">Week 9</option>
                                            <option value="10">Week 10</option>
                                            <option value="11">Week 11</option>
                                            <option value="12">Week 12</option>
                                            <option value="13">Week 13</option>
                                            <option value="14">Week 14</option>
                                            <option value="15">Week 15</option>
                                            <option value="16">Week 16</option>
                                            <option value="17">Week 17</option>
                                            <option value="18">Week 18</option>
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="circaselect-box">
                                        <select className="select-box">
                                            <option>NFL</option>
                                            <option>NFL</option>
                                            <option>NFL</option>
                                            <option>NFL</option>
                                        </select>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {filteredTopList.length > 0 ? (
                        filteredTopList.map((item, index) => (
                            <div key={index} className="circa-box">
                                <div className="circa-box1">
                                    <div className="circalleft">
                                        <div className="cicraleftt">
                                            <div className="cicralefttt">
                                                <img src={`${config.imageUrl}/${item.home_image}`} alt="" />
                                            </div>
                                            <div className="cicracontent">
                                                <h4>{item.home_team}</h4>
                                            </div>
                                        </div>
                                        <div className="cicraleftt">
                                            <div className="cicralefttt">
                                                <img src={`${config.imageUrl}/${item.away_image}`} alt="" />
                                            </div>
                                            <div className="cicracontent">
                                                <h4>{item.away_team}</h4>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="circalcenter">
                                        <img src="images/rectanglecirco.png" alt="" />
                                    </div>
                                    <div className="circalright">
                                        <h3>{item.winning_team}  {item.winning_value}</h3>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="nodata">No data available for the selected week.</p>
                    )}
                </div>
                <section className="survvivor-strategy-blk insidescop">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="circa-heading wade-bakery">
                                    <h2>Get the Inside Scoop</h2>
                                </div>
                                <div className="membership-heading wade-catch">
                                    <p class="latest-1">Catch Our Latest Podcast for Winning Tips</p>
                                </div>
                                <div className="grid-postcard-blk">
                                    <div className="common-blk">
                                        <div className="imgblk">
                                            <img alt="" src="images/poscast.png" />
                                        </div>
                                        <div className="Surviour-btn upgrade-btn">
                                            <Link to="#">LISTEN TO THE PODCAST</Link>

                                            {/* Carousel items */}
                                            <div className="listenpodcast">
                                            <Slider {...settings} ref={sliderRef} > 
                                                {filterPodCast.map(item => (
                                                    <div key={item.id}>
                                                            <h4>{item.title}</h4>
                                                            <div className="audio-part">
                                                                <audio controls src={`${config.imageUrl}/${item.audio}`}>
                                                                </audio>
                                                            </div>
                                                        </div>
                                                ))}
                                            </Slider>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <section className="betting-record">
                <div className="container">
                    <div class="adem-latest-heading">
                        <h2>Wade's Betting Record</h2>
                        <h5>TAP EACH BET TO VIEW THE FULL BETTING SLIP</h5>
                    </div>
                    <div className="divs" id="div1">
                        {/* <h5>Week 1</h5> */}
                        <ul>
                            {betList.slice(0, betItemsToShow).map((item, index) => (
                                <li key={index}>
                                    <div className="adam-list">
                                        <div className="adam-list-left">
                                            <div className="adam-left1">
                                                <h4>{item.game_name}</h4>
                                            </div>
                                            <div className="adam-left2">
                                                <h4>{item.team} - {item.player_name}  {item.spread}</h4>
                                            </div>
                                        </div>
                                        <div className="adam-list-right">
                                            <div className="icon-part">
                                                {/* <img alt="" src="images/dollor.png" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <div className="Surviour-btn load-morebtn">
                            {showBetLoadMore && betList.length > 5 && (
                                <button onClick={handleBetLoadMore}>Load More Bets</button>
                            )}
                            {!showBetLoadMore && betList.length > 5 && (
                                <button onClick={handleBetLoadLess}>Load Less Bets</button>
                            )}
                        </div>
                    </div>

                </div>
            </section>
            <section className="hungry">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="loack-img1">
                                <img alt="" src="images/loack1.png" />
                            </div>
                            <div className="circa-heading wade-bakery">
                                <h2>Hungry for More?</h2>
                            </div>
                            <div className="membership-heading gain-access">
                                <h5>
                                    GAIN ACCESS TO ALL SPORTS WAGERS, ADAM’S BETTING SLIPS &amp; MORE
                                </h5>
                            </div>
                            <div className="Surviour-btn upgrade-btn">
                                <Link to="/membership">UPGRADE TO TIER 3</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    )
}

export default WadeBakery