import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import config from '../config';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phoneno: '',
        message: ''
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Check for spaces and invalid characters (numeric and special) for first_name and last_name
        const isNameField = name === 'first_name' || name === 'last_name';
        const invalidCharacters = /[^a-zA-Z]/; // Allow only letters

        if (name === 'email' && value.includes(' ')) {
            setErrorMessage('Email should not contain spaces.');
            return; 
        }
        if (isNameField) {
            if (value.includes(' ')) {
                setErrorMessage(`${name.replace('_', ' ')} should not contain spaces.`);
                return; 
            } else if (invalidCharacters.test(value)) {
                setErrorMessage(`${name.replace('_', ' ')} should only contain letters.`);
                return; 
            }
        }
        if (name === 'message' && value.startsWith(' ')) {
            setErrorMessage('Message cannot start with a space.');
            return; // Prevent the update if the first character is a space
        }

        // Update the form data for valid inputs
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        setErrorMessage(''); // Clear error if the value is valid
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${config.baseUrl}/add-contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSuccessMessage('Your message has been successfully submitted!');
                setFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    phoneno: '',
                    message: ''
                });
                setErrorMessage('');
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.message || 'Something went wrong. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    return (
        <>
            <Header />
            <section className='signup-form membership-part contact-page'>
                <div className='container'>
                    <div className="heading-serving1">
                        <h2 className='contactheading'>Contact Us</h2>
                    </div>
                    <div className="contact-detail">
                        <div className='row'>
                            {/* <div className='col-md-6'>
                                <div className="box-part contact-box">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    <h4><a href="tel:8475256790">847-525-6790</a></h4>
                                </div>
                            </div> */}
                            <div className='col-md-12'>
                                <div className="box-part contact-box">
                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <h4><a href="mailto:contact@spreadsandlocks.com">contact@spreadsandlocks.com</a></h4>
                                    <div className='footer-left'>
                                        <h4>Follow Us for More Tips</h4>
                                        <ul className="list11 contact-social-icon">
                                            <li>
                                        <Link to="https://x.com/spreadsandlock?s=21&t=5kkrR--M8EBP3f7f5pIiJg" target="_blank" rel="noopener noreferrer">
                                        <img src="images/twittercontact.png" alt="" className='twitter1' /></Link></li>
                                        <li><Link to="https://www.instagram.com/spreadsandlocks/?igsh=cHU0Nzc1MTFpaDl1&utm_source=qr" target="_blank" rel="noopener noreferrer">
                                            <img src="images/instgramcontact.png" alt="" className='instgram'/></Link></li>
                                            {/* <li><img src="images/contactyotube.png" alt="" className='contact-yotube' /></li> */}
                                            <li>
                                            <Link href="https://www.youtube.com/spread" target="_blank" rel="noopener noreferrer">
                                                <img src="images/contactyotube.png" alt="" className='contact-yotube' />
                                            </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='signup-form-sub contact-form'>
                        
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        name="first_name"
                                        placeholder="First Name"
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        name="last_name"
                                        placeholder="Last Name"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                            <div className='col-md-12'>
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                           
                                {/* <div className="col-md-6">
                                    <input
                                        type="text"
                                        name="phoneno"
                                        placeholder="Phone"
                                        value={formData.phoneno}
                                        onChange={handleChange}
                                        required
                                    />
                                </div> */}
                            </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <textarea
                                        name="message"
                                        placeholder='Message'
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <input type="submit" value="Submit" />
                                </div>
                            </div>
                        </form>
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                        {successMessage && <p className="success-message">{successMessage}</p>}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ContactUs;
