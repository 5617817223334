import React, { useState,useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const Serving = () => {
    const [playingVideo, setPlayingVideo] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);    
    const handleVideoClick = (src) => {
        setPlayingVideo(src);
    };
    const updateTableVisibility = (slideIndex) => {
        const startIndex = slideIndex;
        const endIndex = startIndex + 1; // Display 5 items (0-indexed)
    
        document.querySelectorAll('.table-column').forEach(column => {
          const columnIndex = parseInt(column.getAttribute('data-column-index'), 10);
          if (columnIndex >= startIndex + 1 && columnIndex <= endIndex + 1) {
            column.style.display = 'table-cell'; // Use 'table-cell' for table columns
          } else {
            column.style.display = 'none';
          }
        });
      };
    const handleAfterChange = (current) => {
        setCurrentSlide(current);
        updateTableVisibility(current);
      };
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        afterChange: handleAfterChange,
        arrows: true,
        responsive: [ 
            { 
              // tablet 
              breakpoint: 991, 
              settings: { 
                slidesToShow: 1, 
                arrows: false,
                dots: true 
              } 
            }, 
            { 
              // mobile portrait
              breakpoint: 479,
              settings: {
                slidesToShow: 1,
                arrows: false,
                dots: true 
              }
            }
          ]
      };

    return (
        <>
        <div className="background-image desktop-backimg">
            <div className="container">
                <div className="heading-serving1">
                    <h5>SERVING BETTER BETS</h5>
                    <h2>What Makes Our Experts Different</h2>
                </div>

                <div className="serving-box">
                    <div className="row">
                        <div className="col-md-6 mt-4">
                            <div className="box-part">
                                <h4>PROVEN SUCCESS</h4>
                                <p> Wade and Adam’s successes are documented by casinos including Circa, Hollywood Greektown, and FanDuel.  Their contest wins over multiple seasons, and their win/loss figures are transparent and verifiable. </p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                            <div className="box-part">
                                <h4>PASSION OVER PROFIT</h4>
                                <p>Outside of BSL, Wade and Adam have lucrative careers, serious passions, and families—making this is a hobby before it's a business.</p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                            <div className="box-part">
                                <h4>FULL TRANSPARENCY</h4>
                                <p> We offer full transparency where every single piece of advice has full sunlight to all users. There are no two sets of email lists, whale plays, or a phony list of accomplishments.</p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                            <div className="box-part">
                                <h4>HERE TO SERVE</h4>
                                <p>Ultimately, BSL will teach you to make more educated decisions when you bet on your own. And that is the primary value we offer. You don't need a fancy analytic system, to look at the stars, or a Las Vegas address to placeholder bets. We got you.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="learn-btn learnbtn1">
                    <Link to="/membership">CHECK OUT OUR OFFERINGS</Link>

                </div>
            </div>
        </div>

        <div className="background-image serving-mobile">
        <div class="container"> 
            <div className="heading-serving1">
                <h5>SERVING BETTER BETS</h5>
                <h2>What Makes Our Experts Different</h2>
            </div>
        <Slider {...settings} ref={sliderRef}>
                          {/* Carousel items */}

                          <div className="item" data-index={1}>
                          <div className="box-part">
                                <h4>PROVEN SUCCESS</h4>
                                <p> Wade and Adam’s successes are documented by casinos including Circa, Hollywood Greektown, and FanDuel.  Their contest wins over multiple seasons, and their win/loss figures are transparent and Verifiable. </p>
                            </div>
                    
                          </div>
                          <div className="item" data-index={2}>
                          <div className="box-part">
                                <h4>PASSION OVER PROFIT</h4>
                                <p>Outside of BSL, Wade and Adam have lucrative careers, serious passions, and families—making this is a hobby before it's a business.</p>
                            </div>
                          </div>
                          <div className="item" data-index={3}>
                          <div className="box-part">
                                <h4>FULL TRANSPARENCY</h4>
                                <p> We offer full transparency where every single piece of advice has full sunlight to all users. There are no two sets of email lists, whale plays, or a phony list of accomplishments.</p>
                            </div>
                          </div>

                          <div className="item" data-index={4}>
                          <div className="box-part">
                                <h4>HERE TO SERVE</h4>
                                <p>Ultimately, BSL will teach you to make more educated decisions when you bet on your own. And that is the primary value we offer. You don't need a fancy analytic system, to look at the stars, or a Las Vegas address to placeholder bets. We got you.</p>
                            </div>
                          </div>
                          
                        </Slider>
                        <div className="learn-btn learnbtn1">
                    <Link to="/membership">SEE OFFERINGS</Link>

                </div>
        </div>
        </div>   
        </>

    )

}
export default Serving;
