import React from 'react'
import Header from '../Components/Common/Header'
import Footer from '../Components/Common/Footer'

const Privacy = () => {
    return (
        <>
            <Header />
            <div className='privacy'>
                <div className='container'>
                <h1 className='thankyou-text'>Thank You For Payment</h1>
                <h4 className='thankyou-text'>Our Team Will Contact Soon</h4>
            </div>
            </div>
            <Footer />
        </>
    )
}

export default Privacy
