import React, { useState } from 'react';
import config from '../config';
import { Link } from 'react-router-dom';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await fetch(`${config.baseUrl}/forget-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setSuccess(responseData.msg);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Request failed');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <Header />
      <section className="login-part">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="signup-form">
                <h2>
                  Forgot <span>Password</span>
                </h2>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input type="submit" value="Submit" />
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  {success && <p style={{ color: 'green' }}>{success}</p>}
                  <p>
                    <Link to="/login" className='signname2'>Sign In</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
       </div>
      </section>
      <Footer />
    </>
  );
};

export default ForgetPassword;
