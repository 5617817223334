import React from 'react';
import Header from './Components/Common/Header';
import Footer from './Components/Common/Footer';

const NotFound = () => {
  return (
    <>
      <Header />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '70vh',
        fontSize: '3rem',
        fontWeight: 'bold',
        color: '#333'
      }}>
        404 - Page Not Found
      </div>
      <Footer />
    </>
  );
}

export default NotFound;
