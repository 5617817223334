import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


const Header = () => {
  const [firstName, setFirstName] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [membershipDropdownOpen, setMembershipDropdownOpen] = useState(false);
  const [isOutOfPool, setIsOutOfPool] = useState(false);
  const [outOfPoolMessage, setOutOfPoolMessage] = useState('');
  const [topMessage, setTopMessage] = useState('');
  const [cartItemCount, setCartItemCount] = useState(0);
  const [userData, setUserData] = useState([]);
  const [membership, setMembership] = useState('')
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchTopMessage = async () => {
      try {
        const response = await fetch(`${config.baseUrl}/fetch-topmessage`);
        const data = await response.json();
        if (response.ok && data.data.length > 0) {
          setTopMessage(data.data[0].message);
          //console.log(data.data[0].message); 
        } else {
          console.error('Failed to fetch top message:', data.message);
        }
      } catch (error) {
        console.error('Error fetching top message:', error);
      }
    };

    fetchTopMessage(); // Fetch the top message on component mount
  }, []);


  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      fetchUserData(token);
    } else {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log(token)
    //const storedFirstName = localStorage.getItem('firstName');
    const userId = localStorage.getItem('userId');
    console.log(userId, "iddddddddddddd")
    // if (token && storedFirstName) {
    //   setFirstName(storedFirstName);
    // }
    if (userId) {
      fetchUserResults(userId)
    }
  }, []);

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItemCount(storedCart.length);
  }, [])

  const fetchUserData = async (token) => {
    try {
      const response = await fetch(`${config.baseUrl}/welcome`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      if (response.ok) {
        const data = await response.json();
        setUserData(data);
        setFirstName(data.data.first_name)
        setMembership(data.data.membership)
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  console.log(userData, "userData")
  console.log(membership, "memeberShip")

  const fetchUserResults = async (userId) => {
    try {
      const response = await fetch(`${config.baseUrl}/fetchresult/${userId}`);
      const data = await response.json();

      if (response.ok) {
        // Check if any result is 1 (lose) and mark user as out of pool
        const hasLost = data.data.some((selection) => selection.result === 1);
        if (hasLost) {
          setIsOutOfPool(true);
        }
      } else {
        console.error('Failed to fetch results:', data.message);
      }
    } catch (error) {
      console.error('An error occurred while fetching results:', error);
    }
  };


  const handleLogout = async () => {
    try {
      const response = await fetch(`${config.baseUrl}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        localStorage.removeItem('token');
        localStorage.removeItem('firstName');
        localStorage.removeItem('cart');
        setFirstName('');
        Swal.fire({
          icon: 'success',
          title: 'Logout Successfully',
          showConfirmButton: false,
          timer: 2500, // Adjust the timer to control how long the message is displayed
        });

        // Navigate to the login page after the timer ends
        setTimeout(() => {
          navigate('/');
        }, 2500);

      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('An error occurred during logout:', error);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMembershipDropdown = () => {
    setMembershipDropdownOpen(true);
  };

  const toggleMenu = () => {
    document.querySelector(".menu-center").classList.toggle("mystyle");
  };

  const handleScrollToSection = () => {
    if (window.location.pathname !== "/") {
      // If not on the homepage, navigate to it
      navigate("/");

      // Set a delay to ensure the page navigation is complete
      setTimeout(() => {
        const section = document.getElementById('about-us-section');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300); // Adjust this delay if necessary
    } else {
      // If already on the homepage, scroll directly
      const section = document.getElementById('about-us-section');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleSurvivorNavigation = (e) => {
    if (isOutOfPool) {
      e.preventDefault();
      setOutOfPoolMessage('You are out of the pool!');
    }
  };

  const handleMembershipNavigation = (path, requiredMembership) => {
    const token = localStorage.getItem('token');
  
    // Paths accessible without logging in
    const allowedPaths = ['/', '/membership', '/about'];
  
    // Redirect non-logged-in users trying to access restricted paths
    if (!token && !allowedPaths.includes(path)) {
      navigate('/login');
      return;
    }
  
    // Restrict access to /survivor-hub for users without Tier1 subscription
    if (path === '/survivor-hub' && membership !== 'Tier1') {
      Swal.fire({
        title: 'Access Denied',
        text: 'You need to upgrade to Tier 1 to access this page.',
        confirmButtonText: 'OK',
        position: 'top',
        padding: '1em',
      });
      return;
    }
  
    // Restrict Tier1 access to Tier2 or Tier3-only pages
    if (membership === 'Tier1') {
      if (path === '/wade-deli') {
        Swal.fire({
          title: 'Access Denied',
          text: 'You need to upgrade to Tier 2 to access this page.',
          confirmButtonText: 'OK',
          position: 'top',
          padding: '1em',
        });
        return;
      }
      if (path === '/adam-bakery') {
        Swal.fire({
          title: 'Access Denied',
          text: 'You need to upgrade to Tier 3 to access this page.',
          confirmButtonText: 'OK',
          position: 'top',
          padding: '1em',
        });
        return;
      }
    }
  
    // Restrict Tier2 access to Tier3-only pages
    if (membership === 'Tier2' && requiredMembership === 'Tier3') {
      Swal.fire({
        title: 'Access Denied',
        text: 'You need to upgrade to Tier 3 to access this page.',
        confirmButtonText: 'OK',
        position: 'top',
        padding: '1em',
      });
      return;
    }
  
    // Restrict non-members from accessing pages requiring membership
    if (!membership && requiredMembership !== 'null') {
      Swal.fire({
        title: 'Access Denied',
        text: 'You need a membership to access this page.',
        confirmButtonText: 'OK',
        position: 'top',
        padding: '1em',
      });
      return;
    }
  
    navigate(path);
  };
  

  return (
    <>
      <section className="header-part1">
        <div className="header-part">
          <div className="container">
            <p><strong>{topMessage}</strong><i className="fa fa-caret-right" aria-hidden="true"></i>
            </p>
          </div>
        </div>

        <div className="main-header">
          <div className="container">
            <div className="main-2">
              <div className="menu-btn">
                <Link to="/" className="logo-left">
                  <img src="images/logonew.png" alt="" />
                </Link>
                <button type="button" className='click' onClick={toggleMenu}><i className="fa fa-bars" aria-hidden="true"></i>
                </button>
              </div>
              <div className="menu-center">
                <ul>

                  <li>
                    <Link to="/membership">Memberships</Link>
                  </li>
                  <li>
                  
                      <>
                        <Link onMouseEnter={toggleMembershipDropdown}>Survivor Hub</Link>
                        {membershipDropdownOpen && (
                          <ul className="dropdown-menu">

                            <li>
                              <button
                                onClick={() => handleMembershipNavigation('/survivor-pool')}
                                style={{ background: 'none', border: 'none', color: 'white', cursor: 'pointer', padding: 0, textDecoration: 'none' }}
                              >
                                Survivor Pool
                              </button>

                            </li>
                            <li>
                              <button
                                onClick={() => handleMembershipNavigation('/survivor-hub', 'Tier1')}
                                style={{ background: 'none', border: 'none', color: 'white', cursor: 'pointer', padding: 0, textDecoration: 'none' }}
                              >
                                Survivor Hub
                              </button>
                            </li>
                          </ul>
                        )}
                      </>
                 
                  </li>

                  <li>
                    <button
                      onClick={() => handleMembershipNavigation('/nfl-contest', 'Tier1')}
                      style={{ background: 'none', border: 'none', color: 'white', cursor: 'pointer', padding: 0, textDecoration: 'none' }}
                    >
                      NFL Contest Picks
                    </button>

                  </li>
                  <li>
                    <button
                      onClick={() => handleMembershipNavigation('/wade-deli', 'Tier2')}
                      style={{ background: 'none', border: 'none', color: 'white', cursor: 'pointer', padding: 0, textDecoration: 'none' }}
                    >
                      Wade's Deli
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleMembershipNavigation('/adam-bakery', 'Tier3')}
                      style={{ background: 'none', border: 'none', color: 'white', cursor: 'pointer', padding: 0, textDecoration: 'none' }}
                    >
                      Adam's Bakery
                    </button>

                  </li>
                  <li>
                    <button onClick={handleScrollToSection}>About Us</button> {/* Updated here */}
                  </li>
                </ul>
                <div className="sign-right">
                  <div className="account1">
                    <img src="images/account.png" alt="" />
                    <ul>
                      <li>
                        {firstName ? (
                          <>
                            <div className='username'>
                              <Link to="/account">{firstName}</Link> {/* Directly link to dashboard */}
                            </div>
                            <div>
                              <Link onClick={handleLogout}>Logout</Link>
                            </div>
                          </>
                        ) : (
                          <div className=''>
                            <Link to="/login">LOGIN</Link>
                          </div>
                        )}

                      </li>
                    </ul>
                  </div>
                  <div className="cart-img">
                    <Link to="/checkout">
                      {cartItemCount > 0 && (
                        <span className="cart-count">{cartItemCount}</span> // Show count when items in cart
                      )}
                      <img src="images/cart-icon.png" alt="Cart" />
                    </Link>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        {outOfPoolMessage && (
          <div className="out-of-pool-message">
            <p>{outOfPoolMessage}</p>
          </div>
        )}
      </section>

    </>

  )
}


export default Header