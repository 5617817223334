import React, { useState } from 'react';
import config from '../config';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import Swal from 'sweetalert2';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('')
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccessMessage('');

    try {
      const response = await fetch(`${config.baseUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        localStorage.setItem('userId', data.data[0].id);
        localStorage.setItem('firstName', data.data[0].first_name); 
        localStorage.setItem('email', data.data[0].email); 
        localStorage.setItem('phoneNumber', data.data[0].phoneno); 
        Swal.fire({
          icon: 'success',
          title: 'Login Successfully',
          showConfirmButton: false,
          timer: 2500, // Adjust the timer to control how long the message is displayed
        });

        // Navigate to the login page after the timer ends
        setTimeout(() => {
          navigate('/');
        }, 2500);
      } else {
        const errorData = await response.json();
        if (errorData.msg === 'Email or Password is incorrect!') {
          setError('Unregistered email id and password.');
        } else {
          setError(errorData.msg || 'Login failed');
        }
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <Header />
      <section className='signup-form membership-part'>
        <div className='container'>
        <div class="membership-heading">
              {/* <h2>Already Have an Account?</h2> */}
              <h5>LOG IN to access or upgrade your membership</h5>
            </div>
            <div className='signup-form-sub'>
            <form onSubmit={handleSubmit}>
           
                <div className='row'>
                  <div className='col-md-12'>
                  <input
                    type="text"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  </div>
                </div>
             
                <div className='row'>
                  <div className='col-md-12'>
                  <input
                    type="password"
                    placeholder="Enter Your Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  </div>
                </div>
              
              
                <div className='row'>
                  <div className='col-md-12'>
                  <div class="Surviour-btn  signin-btn">
                  <input type="submit" value="Submit" />
                  {error && <p style={{ color: 'red' }}>{error}</p>} 
                  </div>
                  <div class="forget-sign"><div class="left-sign"><Link class="sign-up1" to="/sign-up">Sign Up</Link></div><div class="left-sign"><a class="forget-name" href="/forget-password">Forgot Password</a></div></div>
                  </div>
                </div>
              </form>            
            </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Login;
