import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <section className="footer">

        <div className="container">
          <div className='desktop-footer'>
            <div className="row">
              <div className="col-md-4">
                <div className='footer-left'>
                  <h4>Follow Us for More Tips</h4>
                  <ul className="list11">
                    <li><Link target="_blank" to="https://x.com/spreadsandlock?s=21&t=5kkrR--M8EBP3f7f5pIiJg"><img src="images/twitterx.png" alt="" className='twitter1' /></Link></li>
                    <li><Link target="_blank" to="https://www.instagram.com/spreadsandlocks/?igsh=cHU0Nzc1MTFpaDl1&utm_source=qr"><img src="images/instgram.png" alt="" className='instgram' /></Link></li>
                    <li><img src="images/yotube.png" alt="" /></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className='footerlogo'>
                  <img src="images/footerlogo.png" alt="" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="list-menu">
                  <ul>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/sign-up">Sign Up Now</Link>
                    </li>
                    <li>
                      <Link to="/term-condition">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='mobile-footer'>
            <div className="row">
              <div className='col-md-4'>
                <div className='footerlogo'>
                  <img src="images/footerlogo.png" alt="" />
                </div>
              </div>
              <div className='col-md-4'>
                <div className="list-menu">
                  <ul>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/sign-up">Sign Up Now</Link>
                    </li>
                    <li>
                      <Link to="/term-condition">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-4">
                <div className='footer-left'>
                  <h4>Follow Us for More Tips</h4>
                  <ul className="list11">
                  <li><Link target="_blank"to="https://x.com/spreadsandlock?s=21&t=5kkrR--M8EBP3f7f5pIiJg"><img src="images/twitterx.png" alt="" className='twitter1' /></Link></li>
                    <li><Link target="_blank" to="https://www.instagram.com/spreadsandlocks/?igsh=cHU0Nzc1MTFpaDl1&utm_source=qr"><img src="images/instgram.png" alt="" className='instgram' /></Link></li>
                    <li><img src="images/yotube.png" alt="" /></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright1">
          <div className="container">
            <p>
              Copyright © 2024 Bagels, Spreads and Locks. All rights reserved. Bagels, Spreads and Locks is the home of daily sports betting updates and free picks.
            </p>
          </div>
        </div>
      </section>

    </>
  )
}

export default Footer
