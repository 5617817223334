
const config = {
      // baseUrl: 'http://127.0.0.1:9900',
      api:'c04bedf69172991e351de4803e3252a3',
      //  imageFrontUrl:'http://127.0.0.1:8800/data/uploads',
      //  imageUrl: 'http://127.0.0.1:9000/data/uploads',
      baseUrl: 'https://api.spreadsandlocks.com',
    api:'3b3c34562efa606884e5514c7caef02d',
   imageUrl: 'https://adminapi.spreadsandlocks.com/data/uploads'
  };

export default config;
