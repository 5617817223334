import React, { useState, useEffect } from 'react';
import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import config from '../../config'; 
import './Leaderboard.css';

const nflWeeks = [
    { week: '1', startDate: '2024-09-05', endDate: '2024-09-11' },
    { week: '2', startDate: '2024-09-12', endDate: '2024-09-18' },
    { week: '3', startDate: '2024-09-19', endDate: '2024-09-25' },
    { week: '4', startDate: '2024-09-26', endDate: '2024-10-02' },
    { week: '5', startDate: '2024-10-03', endDate: '2024-10-09' },
    { week: '6', startDate: '2024-10-10', endDate: '2024-10-16' },
    { week: '7', startDate: '2024-10-17', endDate: '2024-10-23' },
    { week: '8', startDate: '2024-10-24', endDate: '2024-10-29' },
    { week: '9', startDate: '2024-10-31', endDate: '2024-11-06' },
    { week: '10', startDate: '2024-11-07', endDate: '2024-11-13' },
    { week: '11', startDate: '2024-11-14', endDate: '2024-11-20' },
    { week: '12', startDate: '2024-11-21', endDate: '2024-11-23' },
    { week: '13', startDate: '2024-11-28', endDate: '2024-12-04' },
    { week: '14', startDate: '2024-12-05', endDate: '2024-12-11' },
    { week: '15', startDate: '2024-12-12', endDate: '2024-12-18' },
    { week: '16', startDate: '2024-12-19', endDate: '2024-12-25' },
    { week: '17', startDate: '2024-12-26', endDate: '2025-01-01' },
    { week: '18', startDate: '2025-01-02', endDate: '2025-01-05' }
];

const Leaderboard = () => {
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek());
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        fetchLeaderboard(selectedWeek);
    }, [selectedWeek]);

    const fetchLeaderboard = async (week) => {
        try {
            const response = await fetch(`${config.baseUrl}/fetchwithpoints`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch leaderboard');
            }

            const data = await response.json();
            console.log(data, "raw data");

            const weekDataKey = `week${week}`;
            const weekData = data.data[weekDataKey] || [];
            const sortedData = weekData.sort((a, b) => b.total_points - a.total_points);
            setLeaderboardData(sortedData);
        } catch (error) {
            setErrorMessage('Error fetching leaderboard: ' + error.message);
        }
    };

    console.log(leaderboardData, "leaderboard data")

    const handleWeekChange = (newWeek) => {
        setSelectedWeek(newWeek);
    };

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,  
        slidesToScroll: 1,
        initialSlide: getCurrentWeekIndex(), 
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        afterChange: (currentIndex) => handleWeekChange(nflWeeks[currentIndex].week)
    };

    return (
        <div>
            <div className="week-carousel">
            <Slider {...sliderSettings}>
                    {nflWeeks.map((week, index) => (
                        <div key={index} className="week-slide">
                            <div className={`week-box ${selectedWeek === week.week ? 'active' : ''}`}>
                                <p>Week {week.week}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className="leaderboard">
    <h3>Leaderboard - Week {selectedWeek}</h3>
    {leaderboardData.length > 0 ? (
        <table className="leaderboard-table">
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>User</th>
                    {/* <th>Total Points</th> */}
                    <th>Teams</th>
                </tr>
            </thead>
            <tbody>
                {leaderboardData.map((entry, index) => {
                    const teamsArray = entry.teams.split(',').map(team => {
                        const teamMatch = team.match(/^(.*?)\s+\((\d+)\)$/); // Match team name and value in parentheses
                        const teamName = teamMatch ? teamMatch[1].trim() : team.trim(); // Extract the team name
                        const teamValue = teamMatch && teamMatch[2] ? parseInt(teamMatch[2], 10) : null; 
                        return { teamName, teamValue }; 
                    });
                    
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{entry.username}</td>
                            {/* <td>{entry.total_points}</td> */}
                            {/* <td>{cleanedTeamName.slice(0, 3)}</td> */}
                            <td>
                            <div className="team-logo-row">
                    {teamsArray.map((teamObj, idx) => {
                        const { teamName, teamValue } = teamObj; // Destructure the team object

                        console.log(`Team: ${teamName}, Value: ${teamValue}`); // Debugging line

                        return (
                            <div key={idx} className="team-logo-wrapper">
                                <img
                                    loading="lazy"
                                    src={`images/${teamName}.svg`}  
                                    alt={`${teamName} logo`}        
                                    className="team-logo"
                                    width={32}
                                    height={32}
                                />
                                <div className={`team-name ${teamValue === 0 || teamValue === 1 ? 'highlight' : ''}`}>
                                    {teamName.slice(0, 3)} {/* Display team name below the logo */}
                                </div>
                            </div>
                        );
                    })}
                </div>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    ) : (
        <p>No leaderboard data available for Week {selectedWeek}.</p>
    )}
    </div>

    {errorMessage && <div className="error-message">{errorMessage}</div>}

</div>
    );
};

        const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
        };

        const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
        };

        function getCurrentWeek() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        for (const week of nflWeeks) {
            const startDate = new Date(week.startDate);
            const endDate = new Date(week.endDate);

            if (today >= startDate && today <= endDate) {
                return week.week;
            }
        }
        return '1';
        }

        function getCurrentWeekIndex() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        for (let i = 0; i < nflWeeks.length; i++) {
            const startDate = new Date(nflWeeks[i].startDate);
            const endDate = new Date(nflWeeks[i].endDate);

            if (today >= startDate && today <= endDate) {
                return i;
            }
        }
        return 0;
    }

export default Leaderboard;
