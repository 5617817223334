import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import './future.css';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import config from '../config';


const AdamKichen = () => {
  const videoPlayerRef = useRef(null);
  const [liveList, setLiveList] = useState([]);
  const [slipList, setSlipList] = useState([]);
  const [futureList, setFutureList] = useState([]);
  const [futureItemsToShow, setFutureItemsToShow] = useState(5);
  const [liveItemsToShow, setLiveItemsToShow] = useState(5);
  const [showFutureLoadMore, setShowFutureLoadMore] = useState(false);
  const [showLiveLoadMore, setShowLiveLoadMore] = useState(false);
  const videoPlayerContainerRef = useRef(null);
  const sliderRef = useRef(null);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [hasAdamSlipsClass, setHasAdamSlipsClass] = useState(true); // Initial state with class
  const [winList, setWinList] = useState([]);
  const [recapList, setRecapList] = useState([]);
  const [playingVideo, setPlayingVideo] = useState(null);

  const handleClick = () => {
    setHasAdamSlipsClass(false); // Remove the class when link is clicked


  };
  const handleVideoClick = (src) => {
    setPlayingVideo(src);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [liveRes, slipRes, recapRes, futureRes, winRes] = await Promise.all([
          fetch(`${config.baseUrl}/list-live`),
          fetch(`${config.baseUrl}/list-slip`),
          fetch(`${config.baseUrl}/list-recap`),
          fetch(`${config.baseUrl}/list-future`),
          fetch(`${config.baseUrl}/list-win`)
        ]);

        const liveData = await liveRes.json();
        const slipData = await slipRes.json();
        const recapData = await recapRes.json();
        const futureData = await futureRes.json();
        const winData = await winRes.json();

        setLiveList(liveData.data);
        setSlipList(slipData.data);
        setRecapList(recapData.data);
        setFutureList(futureData.data);
        setWinList(winData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  // Handle video player functionality
  useEffect(() => {
    const videoPlayer = videoPlayerRef.current;

    const handleVideoClick = () => {
      if (videoPlayerContainerRef.current) {
        videoPlayerContainerRef.current.style.display = 'none'; // Hide the video preview
        videoPlayer.play(); // Start playing the video
      }
    };

    if (videoPlayerContainerRef.current) {
      videoPlayerContainerRef.current.addEventListener('click', handleVideoClick);
    }

    return () => {
      if (videoPlayerContainerRef.current) {
        videoPlayerContainerRef.current.removeEventListener('click', handleVideoClick);
      }
    };
  }, []);

  const handleFutureLoadMore = () => {
    setFutureItemsToShow(futureList.length);
    setShowFutureLoadMore(false);
  };
  console.log(winList)

  const handleFutureLoadLess = () => {
    setFutureItemsToShow(5);
    setShowFutureLoadMore(true);
  };

  const handleLiveLoadMore = () => {
    setLiveItemsToShow(liveList.length);
    setShowLiveLoadMore(false);
  };

  const handleLiveLoadLess = () => {
    setLiveItemsToShow(5);
    setShowLiveLoadMore(true);
  };

  // Handle sliding functionality
  const slideWidth = 33.33; // In percentage
  const totalSlides = 5; // Assume we have 5 slides for now

  const moveSlides = (direction) => {
    if (direction === 'left' && currentPosition > 0) {
      setCurrentPosition(currentPosition - slideWidth);
    } else if (direction === 'right' && currentPosition < (totalSlides - 1) * slideWidth) {
      setCurrentPosition(currentPosition + slideWidth);
    }
  };
  const handleSlideClick = (e) => {
    const videoUrl = e.currentTarget.getAttribute('data-video-url');
    document.querySelector('.images-adam').style.display = "none";
    if (videoUrl && videoPlayerRef.current) {
      videoPlayerRef.current.src = videoUrl;
      videoPlayerRef.current.play();

    }
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const updateTableVisibility = (slideIndex) => {
    const startIndex = slideIndex;
    const endIndex = startIndex + 4; // Display 5 items (0-indexed)

    document.querySelectorAll('.table-column').forEach(column => {
      const columnIndex = parseInt(column.getAttribute('data-column-index'), 10);
      if (columnIndex >= startIndex + 1 && columnIndex <= endIndex + 1) {
        column.style.display = 'table-cell'; // Use 'table-cell' for table columns
      } else {
        column.style.display = 'none';
      }
    });
  };

  const handleAfterChange = (current) => {
    setCurrentSlide(current);
    updateTableVisibility(current);
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    afterChange: handleAfterChange,
    arrows: true,
    responsive: [
      {
        // tablet 
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true
        }
      },
      {
        // mobile portrait
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  console.log(recapList, "recap list")
  const uniqueRecaps = [...new Map(recapList.filter(item => item.status === 1).map(item => [item.id, item])).values()];
  return (
    <>
      <Header />
      <section className="adam-bakery">
        <div className="container">
          <div className="adam-bakerypart">
            <div className="row">
              <div className="col-md-6">
                <img alt="" src="images/adambakery.png" className="desktop" />
                <img alt="" src="images/adambakery-mobile.png" className="mobile" />

              </div>
              <div className="col-md-6">
                <div className="adam-bakery-total">
                  <h5>Quarterly Totals</h5>
                  {winList.map(item => (
                    <div className="row">
                      <div className="col-md-6 col-6 bakery-box1">
                        <div className="bakery-box">
                          <h2>{item.total_win}</h2>
                          <h6>Total Wins</h6>
                        </div>
                      </div>
                      <div className="col-md-6 col-6 bakery-box2">
                        <div className="bakery-box">
                          <h2>{item.total_loss}</h2>
                          <h6>Total Losses</h6>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="adam-latest">

            <div className="container">
              <div className="adem-latest-heading">
                <h2>Adam’s Latest Live Bets</h2>
                <h5>Tap Each Bet to View the Full Betting Slip</h5>
              </div>

              <div className="adam-bakery-list">
                <ul>
                  {liveList.slice(0, liveItemsToShow).map((item, index) => (
                    <li key={index}>
                      <div className="adam-list">
                        <div className="adam-list-left">
                          <div className="adam-left1">
                            <h4>{item.game_name}</h4>
                          </div>
                          <div className="adam-left2">
                            <h4>{item.team} - {item.player_name}  {item.spread}</h4>
                          </div>
                        </div>
                        <div className="adam-list-right">
                          <div className="icon-part">
                            {/* <img alt="" src="images/dollor.png" /> */}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>

                <div className="Surviour-btn load-morebtn">
                  {showLiveLoadMore && liveList.length > 5 && (
                    <button onClick={handleLiveLoadMore}>Load More Live Bets</button>
                  )}
                  {!showLiveLoadMore && liveList.length > 5 && (
                    <button onClick={handleLiveLoadLess}>Load Less Live Bets</button>
                  )}
                </div>
              </div>

            </div>

          </div>
        </div>
      </section>
      <section className="future-bets full-betting">
        <div className="container">
          <div className="adem-latest-heading">
            <h2>Futures Bets</h2>
            <h5>Tap Each Bet to View the Full Betting Slip</h5>
          </div>

          <div className="adam-bakery-list">
            <ul>
              {futureList.slice(0, futureItemsToShow).map((item, index) => (
                <li key={index}>
                  <div className="adam-list">
                    <div className="adam-list-left">
                      <div className="adam-left1">
                        <h4>{item.game_name}</h4>
                      </div>
                      <div className="adam-left2">
                        <h4>{item.team} - {item.player_name}  {item.spread}</h4>
                      </div>
                    </div>
                    <div className="adam-list-right">
                      <div className="icon-part">
                        {/* <img alt="" src="images/dollor.png" /> */}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>

            <div className="Surviour-btn load-morebtn">
              {showFutureLoadMore && futureList.length > 5 && (
                <button onClick={handleFutureLoadMore}>Load More Bets</button>
              )}
              {!showFutureLoadMore && futureList.length > 5 && (
                <button onClick={handleFutureLoadLess}>Load Less Bets</button>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="hungry see-slips">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className='slips-heading'>
                <div className="circa-heading wade-bakery">
                  <h2>See The Slips</h2>
                </div>
                <div className="membership-heading">
                  <h5 className='fullaccess'>FULL ACCESS TO ADAM'S REAL WAGERS</h5>
                </div>
              </div>
            </div>
          </div>
          <div className='slips11'>
            <div className={hasAdamSlipsClass ? 'adam-slips' : ''}>
              <Slider {...settings} ref={sliderRef}>
                {/* Carousel items */}

                {slipList.map((item, index) => (
                  <img
                    alt=""
                    src={`${config.imageUrl}/${item.image}`}
                    key={index}
                    className='blurred-image' />
                ))}


              </Slider>


              <div className="Surviour-btn upgrade-btn view-btn">
                <Link href="#" onClick={handleClick} className={hasAdamSlipsClass ? '' : 'bed-slip'}>
                  VIEW BET SLIP LIBRARY
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="adam-inside">
        <div className="container">
          <div className="circaselect-box jumpdate desktop-jump">
            <select className="select-box" id="dropdown" onChange={() => { }}>
              <option value={1}>Jump To Date</option>
              <option value={2}>Week 2</option>
              <option value={3}>Week 3</option>
            </select>
          </div>

          <section className="adam-inside adam-inside1">
            <div className="container">
              <div class="adem-latest-heading">
                <h2>Inside Adam’s Bets: Recaps &amp; Q&amp;As</h2>
                <p> Watch in-depth breakdowns of Adam's wagers and dive in to the past live Q &amp; A sessions for expert insights
                  and strategies that lead to winning bets.</p>
              </div>

              <div className="circaselect-box jumpdate mobile-jump">
                {/* <select className="select-box" id="dropdown" onChange={() => { }}>
              <option value={1}>Jump To Date</option>
              <option value={2}>Week 2</option>
              <option value={3}>Week 3</option>
            </select> */}
              </div>
            </div>

            <div className="container adam-video">
              {recapList.length > 0 && (
                <div className="video-container" ref={videoPlayerContainerRef}>
                  <div className='images-adam'>
                    <img
                      src={`${config.imageUrl}/${recapList[0].image}`}
                      alt="Sports Betting Tips"
                      className="video-thumbnail mb-2"
                    />
                    <div class="video-title-inside">
                      <p class="inside-title">{recapList[0].title}</p>
                      <p class="inside-date">{formatDate(recapList[0].updated_at)}</p>
                    </div>
                  </div>
                  <video id="videoPlayer" controls ref={videoPlayerRef}>
                    {/* Fix the video path by combining config.imageUrl with the first video in the list */}
                    <source src={`${config.imageUrl}/${recapList[0].video}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
              
              <div className="adam-slider-part">
              <Slider ref={sliderRef} {...sliderSettings}>
              {uniqueRecaps.map(item => (
                <div
                  className="slide"
                  data-video-url={`${config.imageUrl}/${item.video}`}
                  onClick={handleSlideClick}
                  key={item.id} // Ensure each slide has a unique key
                >
                  <div className="images-box">
                    <img src={`${config.imageUrl}/${item.image}`} alt={item.title} />
                    <div className="video-title-inside1">
                      <p className="inside-title">{item.title}</p>
                      <p className="inside-date">{formatDate(item.updated_at)}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
              </div>
            </div>
          </section>

          <div className="mobile-slideradamkitchen">
  <Slider {...settings} ref={sliderRef}>
    {/* Carousel items */}
    {recapList.map((item, index) => (
      <div className="item" data-index={item.index || index} key={index}>
        {playingVideo === `${config?.imageUrl}/${item.video}` ? (
          <video controls width="100%" autoPlay>
            <source src={`${config?.imageUrl}/${item.video}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div
            className="position-relative"
            onClick={() => handleVideoClick(`${config?.imageUrl}/${item.video}`)}
          >
            <img
              src={`${config?.imageUrl}/${item.image}`}
              alt={item.title || 'Video thumbnail'}
              className="video-thumbnail mb-2"
            />
            <div className="video-title-inside1">
              <p className="inside-title">{item.title}</p>
              <p className="inside-date">{formatDate(item.updated_at)}</p>
            </div>
          </div>
        )}
      </div>
    ))}
  </Slider>
</div>
        </div>
      </section>

      <Footer />
    </>

  )
}

export default AdamKichen
