import React, { useState,useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../Pages/videoshow.css';

const VideoShow = () => {
    const [playingVideo, setPlayingVideo] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);    
    const handleVideoClick = (src) => {
        setPlayingVideo(src);
    };
    const updateTableVisibility = (slideIndex) => {
        const startIndex = slideIndex;
        const endIndex = startIndex + 1; // Display 5 items (0-indexed)
    
        document.querySelectorAll('.table-column').forEach(column => {
          const columnIndex = parseInt(column.getAttribute('data-column-index'), 10);
          if (columnIndex >= startIndex + 1 && columnIndex <= endIndex + 1) {
            column.style.display = 'table-cell'; // Use 'table-cell' for table columns
          } else {
            column.style.display = 'none';
          }
        });
      };
    const handleAfterChange = (current) => {
        setCurrentSlide(current);
        updateTableVisibility(current);
      };
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        afterChange: handleAfterChange,
        arrows: true,
        responsive: [ 
            { 
              // tablet 
              breakpoint: 991, 
              settings: { 
                slidesToShow: 1, 
                arrows: false,
                dots: true 
              } 
            }, 
            { 
              // mobile portrait
              breakpoint: 479,
              settings: {
                slidesToShow: 1,
                arrows: false,
                dots: true 
              }
            }
          ]
      };


    

    return (
        <>
            {/* { <section className="video-shows">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="video-heading">
                                <h2>VIDEOS AND SHOWS FROM OUR EXPERT</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                            {playingVideo === "/images/wade.mp4" ? (
                                <video controls width="100%" autoPlay>
                                    <source src="/images/wade.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <div
                                    className="video-wrapper position-relative"
                                    onClick={() => handleVideoClick("/images/wade.mp4")}
                                >
                                    <img
                                        src="images/expert2.png"
                                        alt="Sports Betting Tips"
                                        className="video-thumbnail mb-2"
                                    />
                                    <p className="video-title">Wade</p>
                                </div>
                            )}
                        </div>
                        <div className="col-md-6">
                            {playingVideo === "/images/adam.mp4" ? (
                                <video controls width="100%" autoPlay>
                                    <source src="/images/adam.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <div
                                    className="video-wrapper position-relative"
                                    onClick={() => handleVideoClick("/images/adam.mp4")}
                                >
                                    <img
                                        src="images/anchor1.png"
                                        alt="Sports Betting Tips"
                                        className="video-thumbnail mb-2"
                                    />
                                    <p className="video-title">Adam</p>
                                </div>
                            )}
                        </div>
                      
                    </div>
                </div>
            </section> } */}

            <div className="background-image video-part">
            <div className="container">
                <div className="heading-serving1">
                    <h5>Spreading the Knowledge:</h5>
                    <h2>Watch & Learn from the Pros</h2>
                </div>

                <Slider {...settings} ref={sliderRef}>
                          {/* Carousel items */}

                          <div className="item" data-index={1}>
                            {/* <img alt="" src="images/expert2.png" />
                            <h5 className='learn1'>Learn XYZ from Wade.</h5> */}
                                   {playingVideo === "/images/wade.mp4" ? (
                                <video controls width="100%" autoPlay>
                                    <source src="/images/wade.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <div
                                    className="video-wrapper position-relative"
                                    onClick={() => handleVideoClick("/images/wade.mp4")}
                                >
                                    <img
                                        src="images/expert2.png"
                                        alt="Sports Betting Tips"
                                        className="video-thumbnail mb-2"
                                    />
                                    <p className="video-title">Learn From Wade</p>
                                </div>
                            )}
                          </div>
                          <div className="item" data-index={2}>
                          {playingVideo === "/images/adam.mp4" ? (
                                <video controls width="100%" autoPlay>
                                    <source src="/images/adam.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <div
                                    className="video-wrapper position-relative"
                                    onClick={() => handleVideoClick("/images/adam.mp4")}
                                >
                                    <img
                                        src="images/anchor1.png"
                                        alt="Sports Betting Tips"
                                        className="video-thumbnail mb-2"
                                    />
                                    <p className="video-title">Learn From Adam</p>
                                </div>
                            )}
                            {/* <img alt="" src="images/anchor1.png" />
                            <h5  className='learn1'>Learn XYZ from Adam.</h5> */}
                          </div>
                          
                        </Slider>
                
            </div>
        </div>   
        </>
    );
};

export default VideoShow;
