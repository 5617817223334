import React from 'react';

const Abouts = () => {
  const handleScrollToSection = () => {
    const section = document.getElementById('about-us-section');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="main-banner">
        <div id="demo" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
            <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="images/main-banner1.jpg" alt="" className="d-block desktopview w-100" />
              <img src="images/mobile-banner1.jpg" className="mobileview" alt="" />
              <div className="container-fluid">
                <div className="banner-content">
                  <h2>Bet Better with Insights from Honest Experts</h2>
                  <div className="Surviour-btn learn-btn">
                    {/* Use a button or anchor tag to handle the scroll */}
                    <button onClick={handleScrollToSection} className="btn-link">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <img src="images/mainebanner2.jpg" alt="" className="d-block desktopview w-100" />
              <img src="images/mobile-banner2.jpg" className="mobileview" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Abouts;
