import React from 'react'
import Header from '../Components/Common/Header'
import Footer from '../Components/Common/Footer'

const TermCondition = () => {
    return (
        <>
            <Header />
            <div className='privacy'>
            <div className='container'>
            <p>Bagelswithlocks.com is a sports information and entertainment website only. It does not accept or take bets. It is not a gambling website and this is not a gambling operator. 18 and over only.</p>

            {/* <p>I want a flag when you make an account that makes you read the terms and conditions and verify your age.</p> */}


            <p>The following are terms of a legal agreement between Bagels With Locks, LLC and all of its affiliates, subsidiaries, agents, assigns, or any representative whatsoever (“BWL”) and you. By accessing, reading and using this website, you acknowledge that you have read, understood, and agree to be bound by the terms herein and to also comply with all applicable laws and regulations, both federal and in the state in which you are accessing this website. If you do not agree to these terms, do not use this website. This website may contain other proprietary and copyright information, the terms of which must be observed and followed. Information on this website may be technically inaccurate or have errors. Be aware that PFF may, in its sole discretion and without notice, may revise these terms at any time and it is the responsibility of the user of this website to ensure that the user of this website has read the most recent version of these terms.</p>
            <h2>USING THIS WEBSITE</h2>
            <p>The copyright in all materials provided on this website is held by BWL or by the original creator of the material. All rights not expressedly granted are reserved. When you create an account on this website, permission is granted only to the person identified on the account to access and use the materials on this website for your personal use. You may not share your login credentials with any other person or entity. You may not share the materials in any form (hard copy, screen shot, copy and paste) with any other person or entity. Those caught disseminating the information contained in the member only part of the website that requires an account and log in will be banned from the website forever. You may not “mirror” any material contained on this website. All content and functionality on this website, including text, graphics, logos, icons, and images and the selection and arrangement thereof, are the exclusive property of BWL and are protected by relevant intellectual property law. BWL makes no representations about any other website which you may access through BWL. When you access a non-BWL website, BWL does not control the same. In addition, a link or referral to a web site does not mean that BWL endorses or accepts any responsibility for the content or the use of the same. In fact, BWL disclaims any and all liability and responsibility for any other third party in this regard. It is up to you to be cautious to avoid malware or other undesirable issues you may encounter on other websites.</p>
            <h2>TRADEMARKS</h2>
            <p>Any trademark used and displayed on this website may be registered or unregistered Trademarks of BWL. Any trademarks, service marks and trade names on this website that are not BWL-related may be owned by other parties. Nothing on this website should be construed as granting, by implication, estoppel or otherwise, any license or right to use any Trademark or any other BWL intellectual property including the name Bagels With Locks, or Locks, Spreads, and Bagels, without prior written permission.</p>
            <h2>NO SERVICES, ENDORSEMENT OR PROFESSIONAL CONSULTATION</h2>
            <p>There may be delays, omissions, inaccuracies, or outright false information obtained through your use of this website. The information on this website is not investment, consulting, legal, accounting, tax, career or other advice or services. You should not rely on the information on this website to make business, investment or other decisions or used as a substitute for consultation with relevant professionals. BWL does not represent or endorse the accuracy or reliability of any advice, opinion, statement, or other information displayed, uploaded, downloaded or distributed through this website. You acknowledge that any reliance upon such opinion, advice, statement, memorandum, or information shall be at your sole option, liability and risk. Moreover, BWL does not grant any license or other authorization to you to use this website in any manner if such use in whole or in part suggests that BWL promotes or endorses a third party's causes, ideas, political campaigns, political views, web sites, products or services. You consume the information on this site, in all its form, and then make your own decisions. You agree to hold BWL harmless for any and all liability related to any information on this website whatsoever or any content posted by any BWL agent anywhere at any time. </p>
            <h2>ACCESS</h2>
            <p>BWL may alter, suspend or discontinue this website and/or your access to use this website at any time for any reason without notice or liability to you or any third party. This website may become unavailable due to maintenance or malfunction of computer equipment or for other reasons and may result in damages to the user's systems or operations. The user shall be solely responsible for ensuring that any information or content obtained from this Site does not contain any virus or other computer software code or subroutine designed to disable, erase, impair or otherwise damage the user's systems, software or data.</p>
            <h2>DISCLAIMER OF WARRANTIES</h2>
            <p>THE WEBSITE AND ALL MATERIALS THEREON ARE DISTRIBUTED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, BWL DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. SPECIFICALLY, BUT WITHOUT LIMITATION, BWL DOES NOT WARRANT THAT: (1) THE INFORMATION ON THIS WEBSITE IS CORRECT, ACCURATE OR RELIABLE; (2) THE FUNCTIONS CONTAINED ON THIS WEBSITE WILL BE UNINTERRUPTED OR ERROR-FREE; OR (3) DEFECTS WILL BE CORRECTED, OR THAT THIS WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU HEREBY ACKNOWLEDGE THAT USE OF THE WEBSITE IS AT YOUR SOLE RISK. IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 WHICH PROVIDES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”</p>
            <h2 >LIMITATION OF LIABILITY</h2>
            <p >UNDER NO CIRCUMSTANCES SHALL BWL OR ANY OF ITS PREDECESSORS, SUCCESSORS, PARENTS, SUBSIDIARIES, AFFILIATES, COACHES, OFFICERS, DIRECTORS, SHAREHOLDERS, INVESTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, ATTORNEYS AND THEIR RESPECTIVE HEIRS, SUCCESSORS AND ASSIGNS BE LIABLE FOR ANY DAMAGES, INCLUDING DIRECT, INCIDENTAL, PUNITIVE, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES THAT DIRECTLY OR INDIRECTLY RESULT FROM THE USE OF, OR THE INABILITY TO USE, THIS WEBSITE OR THE INFORMATION CONTAINED ON THIS WEBSITE OR OBTAINED FROM YOUR USE OF THIS WEBSITE, INCLUDING FOR VIRUSES ALLEGED TO HAVE BEEN OBTAINED FROM THE WEBSITE, EVEN IF BWL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL IT OR ANY OF ITS PREDECESSORS, SUCCESSORS, PARENTS, SUBSIDIARIES, AFFILIATES, COACHES,OFFICERS, DIRECTORS, SHAREHOLDERS, INVESTORS', EMPLOYEES', AGENTS', REPRESENTATIVES' AND ATTORNEYS' AND THEIR RESPECTIVE HEIRS', SUCCESSORS' AND ASSIGNS' SHARE IN ANY LIABILITY. TOTAL LIABILITY DEVOLVES TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE) OR OTHERWISE EXCEEDING $1. SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN WHICH CASE SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO ALL USERS.</p>
            <h2 >INDEMNIFICATION</h2>
            <p >You hereby indemnify, defend, and hold harmless BWL and all of its predecessors, successors, parents, subsidiaries, affiliates, coaches, officers, directors, shareholders, investors, employees, agents, representatives and attorneys and their respective heirs, successors and assigns (collectively, the “Indemnified Parties”) from and against any and all liability and costs, including, without limitation, reasonable attorneys' fees, incurred by the Indemnified Parties in connection with any claim arising out of or relating to any breach by you of this agreement or the representations, warranties, and covenants you have made by agreeing to the terms of this agreement. You shall cooperate as fully as reasonably required in the defense of any such claim. Bridgers reserves the right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnification by you.</p>
            <h2 >ENFORCEMENT OF TERMS AND CONDITIONS</h2>
            <p>This agreement is governed and interpreted pursuant to the laws of the State of Michigan, United States of America, notwithstanding any principles of conflicts of law. You expressly agree that exclusive jurisdiction resides in the courts of the State of Michigan. You further agree and expressly consent to the exercise of personal jurisdiction in the State of Michigan in connection with any dispute or claim involving BWL. If any part of these terms is unlawful, void, or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of the remaining provisions.</p>
            <h2>ENTIRE AGREEMENT</h2>
            <p>This agreement constitutes the entire agreement between BWL and you with respect to the subject matter of this agreement and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding that subject matter. Any waiver of any provision of this agreement will be effective only if in writing and signed by BWL.</p>
            </div>
            </div>
            <Footer />
        </>
    )
}

export default TermCondition
