import React from 'react'
import Header from '../Components/Common/Header'
import Footer from '../Components/Common/Footer'

const Privacy = () => {
    return (
        <>
            <Header />
            <div className='privacy'>
                <div className='container'>
                <p> Bagels With Locks takes your privacy seriously. Therefore, we collect and use personal data only as it might be needed for us to deliver to you our world-class products, services, and websites. Your personal data includes information including, but not necessarily limited to:</p>
                <ul>
                    <li>Name</li>
                    <li>Address</li>
                    <li>Telephone number</li>
                    <li>Date of birth</li>
                    <li>Email address</li>
                    <li>Other data collected that could directly or indirectly identify you.</li>
                </ul>
                <p>This Privacy Policy is intended to describe how we collect your data and how we endeavor to protect it. It also describes your options and relationship with us related to your privacy.</p>
                <p>Any questions you may have about our Privacy Policy should be e-mailed to: contact@spreadsandlocks.com.</p>
                <p> The primary purpose for collecting any information is to provide the best possible user experience. Primarily, information we collect from you will be when you create an account or make a purchase. Obviously, there will be other times we may collect or need your information, such as, but not limited to requesting assistance from us.
                    Less obviously though are things like cookies or other tracking technologies, and end-user website activity on our website that tracks browsing tendencies like links clicked, items purchased, etc. Data like this allows us to provide you with a better product because we can tailor the experience to what people seem to be enjoying or using most. Things like metadata, log files, cookie/device IDs and location information is automatically collected. This will give us information about which features, content and links were used.
                    Here is what is most important to us: (1) we collect data that is minimal and limited to what you do on our site or what is necessary to provide our service; (2) we limit the use of any data to what we have been given permission, what is necessary to provide services, or what is required by law. Some examples of the uses of your data include, but are not limited to, improving our operation, triaging problems, preventing fraud and abuse, and collecting bulk statistics to analyze for our service.
                    We are not interested in invading your privacy, that is why the vast majority of data is aggregated and does not identify you individually, but rather your actions on our site more broadly. In addition, we are very careful with what third parties share our data. Some include, but are not limited to, credit card processors, advertisers, survey or contest companies, and third party marketing analysts, among others. We ensure that anyone we share your data with also takes your privacy seriously. And, we only share your personal data as necessary to provide the services as requested or as needed on our behalf.
                    We obviously will use your information to contact you directly. But we may also contact you through third parties regarding products or services expect or have signed up for, or other things purchased from us. We may also contact you with offers for additional services we think you’ll find valuable. If we collect information from you in connection with a co-branded offer, it will be clear at the point of collection who is collecting the information and whose privacy policy applies. If you utilize our Services from a country other than the United States we may transfer your personal data across international borders.
                    If you believe that anyone has provided us with your personal information and you would like to request that it be removed from our database, please contact us at <a href="mailto:contact@spreadsandlocks.com">contact@spreadsandlocks.com</a>.</p>
                <p>This is important. We cooperate with government and law enforcement and otherwise comply with the law. We will disclose any information about you to government or law enforcement officials or others as we, in our sole discretion, believe necessary or required in the legal process (such as subpoenas). We will only notify you of the same if permitted by law. If permitted, we will do so in a time permitted by law.
                    We use multiple web analytics tools, such as Google Analytics, to collect information about how you interact with our website. We use the information provided by these tools to improve our services. These tools may place persistent cookies in your browser. But these cannot be used by anyone but the service provider. The information collected from the cookie may be transmitted to and stored by these service partners on servers in a country other than the country in which you reside. This will not include personal identifiers like name, address, and billing information. You can control the technologies we use by managing your settings. Some browsers allow you to automatically notify websites you visit not to track you using a “Do Not Track” signal. There is no consensus among industry participants as to what “Do Not Track” means. Like many websites and online services, we currently do not alter our practices when we receive a “Do Not Track” signal from a visitor’s browser. To find out more about “Do Not Track,” you may wish to visit www.allaboutdnt.com.
                    Targeted ads may be presented to you based on your activities on our webpages, and other websites. Our website contains links to third-party websites. We are not responsible for the privacy practices or the content of third-party sites.</p>
                <p>You can always view the personal data you have shared with us by logging into your account. If you want to delete all the data associated with your account, please e-mail <a href="mailto:contact@spreadsandlocks.com">contact@spreadsandlocks.com</a> requesting the same. We will meet this request if we no longer need any of your data to provide services or as otherwise necessary to comply with legal or record-keeping obligations.
                    We follow generally accepted standards to store and protect the personal data we collect. We keep personal data only for as long as necessary to provide services and/or thereafter for a variety of legitimate legal or business purposes. These might include requirements by law, contract, or other legitimate business purposes.
                    Our Services are available for purchase only for those over the age of 18. Our Services are not targeted to, intended to be consumed by or designed to entice individuals under the age of 18. If you know of or have reason to believe anyone under the age of 18 has provided us with any personal data, please contact us.
                    We reserve the right to modify this Privacy Policy at any time. If we decide to change our Privacy Policy, we will post those changes to this Privacy Policy and any other places we deem appropriate, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.</p>
                <h2>Contact Us</h2>
                <p>If you have any questions, concerns or complaints about our Privacy Policy, our practices or our Services. You may contact us at <br /><strong><a href="tel:8475256790">847-525-6790</a></strong> or&nbsp;<strong><a href="mailto:contact@spreadsandlocks.com">contact@spreadsandlocks.com</a></strong>. 
                We will respond to all requests, inquiries or concerns within thirty (30) days.</p>
               

            </div>
            </div>
            <Footer />
        </>
    )
}

export default Privacy
